import { useRef, useState } from 'react';
import { useAtom } from 'jotai';
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useScroll,
  useTransform,
} from 'framer-motion';
import classNames from 'classnames';

import styl from '../Web3/styles.module.scss';

import KeyVisual1 from '@Images/keyvisual/kv1.png';
import KeyVisual2 from '@Images/keyvisual/kv2.png';
import KeyVisual3 from '@Images/keyvisual/kv3.png';
import KeyVisual4 from '@Images/keyvisual/kv4.png';
import KeyVisual5 from '@Images/keyvisual/kv5.png';
import KeyVisual6 from '@Images/keyvisual/kv6.png';
import KeyVisual7 from '@Images/keyvisual/kv7.png';
import KeyVisual8 from '@Images/keyvisual/kv8.png';
import KeyVisual9 from '@Images/keyvisual/kv9.png';
import KeyVisual10 from '@Images/keyvisual/kv10.png';
import KeyVisual13 from '@Images/keyvisual/kv13.png';

import FeedBubble from '@Images/feed/text-bubble.png';
import ProfileFeed1 from '@Images/feed/ProfileFeed1.png';
import ProfileFeed2 from '@Images/feed/ProfileFeed2.png';
import ProfileFeed3 from '@Images/feed/ProfileFeed3.png';
import ProfileFeed4 from '@Images/feed/ProfileFeed4.png';
import ProfileFeed5 from '@Images/feed/ProfileFeed5.png';

import PlayerIcon from '@Images/player/gift.png';
import GiftokPlayer from '@Components/module/GiftokPlayer';

import InviteIcon from '@Images/invite/icon_invite.png';
import ProfileNFT from '@Images/invite/profile-nft.png';
import LockCircle from '@Images/invite/lock_circle.png';
import TicketImg from '@Images/invite/ticket.png';
import GatingCheck from '@Images/invite/gating-check.png';
import ProfileCommaOrange from '@Images/invite/profile-comma-orange.png';
import Profile from '@Components/core/Profile';

import { isMobileAtom, screenHeightAtom } from '../../logics/atom';
import { onChange, useRange } from '../../utils/hooks';
import SectionHeader from '@Components/module/SectionHeader';
import StoreIcon from '@Images/store/icon_store.png';
import { useTranslation } from 'react-i18next';
import IconTitle from '@/pages/Web3/component/IconTitle';

export default function Layer3({ changeHeaderColor }) {
  const { t } = useTranslation();
  const section3Messages = t('web3.section3.messages', { returnObjects: true });
  const section3MessagesM = t('web3.section3.messagesM', {
    returnObjects: true,
  });
  const section5Messages = t('web3.section5.messages', { returnObjects: true });
  const section5MessagesM = t('web3.section5.messagesM', {
    returnObjects: true,
  });
  const [screenHeight] = useAtom(screenHeightAtom);
  const [isMobile] = useAtom(isMobileAtom);
  const [chatIndex, setChatIndex] = useState(0);

  const comments = [
    {
      thumb: ProfileFeed3,
      text: isMobile ? section3MessagesM[0] : section3Messages[0],
      comma: true,
      color: 'green',
    },
    {
      thumb: ProfileFeed2,
      text: isMobile ? section3MessagesM[1] : section3Messages[1],
    },
    {
      thumb: ProfileFeed5,
      text: isMobile ? section3MessagesM[2] : section3Messages[2],
    },
    {
      thumb: ProfileFeed1,
      text: isMobile ? section3MessagesM[3] : section3Messages[3],
    },
    {
      thumb: ProfileFeed4,
      text: isMobile ? section3MessagesM[4] : section3Messages[4],
    },
  ];

  const keyViualGroupRef = useRef(null);
  const feedRef = useRef(null);
  const playerRef = useRef(null);

  const chat1Ref = useRef(null);
  const chat2Ref = useRef(null);
  const chat3Ref = useRef(null);
  const chat4Ref = useRef(null);
  const chat5Ref = useRef(null);
  const chat6Ref = useRef(null);
  const chat7Ref = useRef(null);

  const { scrollYProgress: groupProgress } = useScroll({
    layoutEffect: false,
    target: keyViualGroupRef,
    offset: ['start center', 'end end'],
  });
  const { scrollYProgress: feedProgress } = useScroll({
    layoutEffect: false,
    target: feedRef,
    offset: ['start center', 'start start'],
  });
  const { scrollYProgress: feedFullProgress } = useScroll({
    layoutEffect: false,
    target: feedRef,
    offset: ['start start', 'end end'],
  });
  const { scrollYProgress: playerProgress } = useScroll({
    layoutEffect: false,
    target: playerRef,
    offset: ['start center', 'start start'],
  });
  const { scrollYProgress: playerFullProgress } = useScroll({
    layoutEffect: false,
    target: playerRef,
    offset: ['end end', 'end start'],
  });
  const { scrollYProgress: playerBGProgress } = useScroll({
    layoutEffect: false,
    target: playerRef,
    offset: ['start end', 'start center'],
  });

  const card1Y = useRange(
    groupProgress,
    [
      [0.2, 0.35],
      ['100%', '0%'],
    ],
    [
      [0.2, 0.35],
      ['100%', '0%'],
    ],
  );
  const card2Y = useRange(
    groupProgress,
    [
      [0.15, 0.3],
      ['100%', '0%'],
    ],
    [
      [0.15, 0.3],
      ['100%', '0%'],
    ],
  );
  const card3Y = useRange(
    groupProgress,
    [
      [0.1, 0.25],
      ['100%', '0%'],
    ],
    [
      [0.1, 0.25],
      ['100%', '0%'],
    ],
  );
  const card4Y = useRange(
    groupProgress,
    [
      [0.05, 0.2],
      ['100%', '0%'],
    ],
    [
      [0.05, 0.2],
      ['100%', '0%'],
    ],
  );
  const card5Y = useRange(
    groupProgress,
    [
      [0.0, 0.15],
      ['100%', '0%'],
    ],
    [
      [0.0, 0.15],
      ['100%', '0%'],
    ],
  );

  const keyVisualBG = useTransform(
    groupProgress,
    [0.4, 0.6],
    ['#B2FF00', '#FF9300'],
  );
  const playerBG = useTransform(
    playerBGProgress,
    [0, 1],
    ['#FF9300', '#C6AEED'],
  );
  const inviteBG = useTransform(
    playerFullProgress,
    [0, 1],
    ['#C6AEED', '#287846'],
  );

  onChange(keyVisualBG, (last) => backgroundColor.set(last));
  onChange(playerBG, (last) => backgroundColor.set(last));
  onChange(inviteBG, (last) => backgroundColor.set(last));

  const groupTranslateX = useRange(
    groupProgress,
    [
      [0, 0.85],
      ['100%', '-100%'],
    ],
    [
      [0, 0.85],
      ['0%', '-100%'],
    ],
  );
  const feedStickyY = useTransform(feedProgress, [0, 0.5], ['0%', '-50%']);

  onChange(feedFullProgress, (last) => {
    if (last < 0.1 && chatIndex !== 0) setChatIndex(0);
    else if (last > 0.1 && last < 0.2 && chatIndex !== 1) setChatIndex(1);
    else if (last > 0.2 && last < 0.3 && chatIndex !== 2) setChatIndex(2);
    else if (last > 0.3 && last < 0.4 && chatIndex !== 3) setChatIndex(3);
    else if (last > 0.4 && last < 0.5 && chatIndex !== 4) setChatIndex(4);
    else if (last > 0.5 && last < 0.6 && chatIndex !== 5) setChatIndex(5);
  });

  const backgroundColor = useMotionValue('#B2FF00');

  function makeScroll(offset) {
    return (ref) =>
      useScroll({
        layoutEffect: false,
        target: ref,
        offset: offset,
      });
  }

  const [text1Show, setText1Show] = useState(false);
  const [text2Show, setText2Show] = useState(false);
  const [text3Show, setText3Show] = useState(false);
  const [text4Show, setText4Show] = useState(false);
  const [text5Show, setText5Show] = useState(false);
  const [text6Show, setText6Show] = useState(false);
  const [text7Show, setText7Show] = useState(false);

  const chatScroll = makeScroll(['center end', 'start start']);
  const { scrollYProgress: chat1Progress } = chatScroll(chat1Ref);
  const { scrollYProgress: chat2Progress } = chatScroll(chat2Ref);
  const { scrollYProgress: chat3Progress } = chatScroll(chat3Ref);
  const { scrollYProgress: chat4Progress } = chatScroll(chat4Ref);
  const { scrollYProgress: chat5Progress } = chatScroll(chat5Ref);
  const { scrollYProgress: chat6Progress } = chatScroll(chat6Ref);
  const { scrollYProgress: chat7Progress } = chatScroll(chat7Ref);

  onChange(chat1Progress, (last) => {
    if (last > 0.35 && text1Show === false) setText1Show(true);
    else if (last < 0.35 && text1Show === true) setText1Show(false);
  });

  onChange(chat2Progress, (last) => {
    if (last > 0.5 && text2Show === false) setText2Show(true);
    else if (last < 0.5 && text2Show === true) setText2Show(false);
  });

  onChange(chat3Progress, (last) => {
    if (last > 0.35 && text3Show === false) setText3Show(true);
    else if (last < 0.35 && text3Show === true) setText3Show(false);
  });

  onChange(chat4Progress, (last) => {
    if (last > 0.35 && text4Show === false) setText4Show(true);
    else if (last < 0.35 && text4Show === true) setText4Show(false);
  });

  onChange(chat5Progress, (last) => {
    if (last > 0.35 && text5Show === false) setText5Show(true);
    else if (last < 0.35 && text5Show === true) setText5Show(false);
  });

  onChange(chat6Progress, (last) => {
    if (last > 0.35 && text6Show === false) setText6Show(true);
    else if (last < 0.35 && text6Show === true) setText6Show(false);
  });

  onChange(chat7Progress, (last) => {
    if (last > 0.35 && text7Show === false) setText7Show(true);
    else if (last < 0.35 && text7Show === true) setText7Show(false);
  });

  // invite
  const [showInvitee, setShowInvitee] = useState(false);
  const [showInviteeCheck, setShowInviteeCheck] = useState(false);
  const inviteRef = useRef(null);
  const [showTransitionText, setShowTransitionText] = useState(false);
  const [showTransitionText2, setShowTransitionText2] = useState(false);
  const inviteScroll = makeScroll(['start start', 'end end']);
  const { scrollYProgress: inviteProgress } = inviteScroll(inviteRef);

  onChange(inviteProgress, (last) => {
    if (last > 0.1 && showInvitee === false) setShowInvitee(true);
    else if (last < 0.1 && showInvitee === true) setShowInvitee(false);
    if (last > 0.3 && showInviteeCheck === false) setShowInviteeCheck(true);
    else if (last < 0.3 && showInviteeCheck === true)
      setShowInviteeCheck(false);
    if (last > 0.5 && showTransitionText === false) setShowTransitionText(true);
    else if (last < 0.5 && showTransitionText === true)
      setShowTransitionText(false);
    if (last > 0.85 && showTransitionText2 === false)
      setShowTransitionText2(true);
    else if (last < 0.85 && showTransitionText2 === true)
      setShowTransitionText2(false);
  });

  // transition text
  const tansitionBgScaleX = useTransform(inviteProgress, [0.65, 1], [40, 1800]);
  const tansitionBgScaleY = useTransform(inviteProgress, [0.65, 1], [20, 1300]);
  const feedVisualBlur = useTransform(feedFullProgress, [0.1, 0.6], [0, 10]);
  const feedVisualOP = useTransform(feedFullProgress, [0.1, 0.6], [1, 0.4]);
  const filterBlurText = useMotionTemplate`blur(${feedVisualBlur}px)`;
  const cardsRef = useRef(null);

  return (
    <motion.div className={styl.layer3}>
      <div
        ref={keyViualGroupRef}
        className={styl.keyVisuals}
        style={{ height: screenHeight * 2 }}
      >
        <motion.div className={styl.sticky} style={{ backgroundColor }}>
          <motion.div
            ref={cardsRef}
            className={styl.cards}
            style={{ translateX: groupTranslateX }}
          >
            <div className={styl.deck}>
              <motion.img
                src={KeyVisual1}
                style={isMobile ? { translateY: card1Y } : {}}
              />
              <motion.img
                src={KeyVisual2}
                style={isMobile ? { translateY: card2Y } : {}}
              />
              <motion.img
                src={KeyVisual3}
                style={isMobile ? { translateY: card3Y } : {}}
              />
              <motion.img
                src={KeyVisual4}
                style={isMobile ? { translateY: card4Y } : {}}
              />
              <motion.img
                src={KeyVisual5}
                style={isMobile ? { translateY: card5Y } : {}}
              />
              <motion.img
                src={KeyVisual6}
                style={isMobile ? { translateY: card5Y } : {}}
              />
              <motion.img
                src={KeyVisual7}
                style={isMobile ? { translateY: card5Y } : {}}
              />
              <motion.img src={KeyVisual8} />
              <motion.img src={KeyVisual9} />
              <motion.img src={KeyVisual10} />
              <motion.img
                src={KeyVisual13}
                style={isMobile ? { translateY: card4Y } : {}}
              />
            </div>
          </motion.div>
        </motion.div>
      </div>

      <motion.div style={{ backgroundColor }}>
        <motion.div
          style={{ marginTop: screenHeight * -0.2 }}
          className={styl.contents}
        >
          <IconTitle
            center
            icon={<img src={FeedBubble} alt="feed-bubble" />}
            title={
              isMobile ? t('web3.section3.titleM') : t('web3.section3.title')
            }
            desc={t('web3.section3.desc')}
          />

          <div
            ref={feedRef}
            className="holder"
            style={{ height: screenHeight * 2 }}
          >
            <motion.div
              className={styl.sticky}
              style={{ translateY: feedStickyY }}
            >
              <div className={styl.feed}>
                <div className={styl.visual}>
                  <motion.img
                    src={require(`@Images/${t('web3.section3.image')}`)}
                    alt=""
                    style={
                      isMobile
                        ? { filter: filterBlurText, opacity: feedVisualOP }
                        : {}
                    }
                  />
                  <div>
                    {comments.map((cmt, index) => (
                      <motion.div
                        key={cmt.text}
                        style={{ opacity: chatIndex >= index + 1 ? 1 : 0 }}
                        className={classNames(
                          styl.bubble,
                          {
                            [styl.comma]: cmt.comma,
                          },
                          cmt.color === 'green' ? styl.green : '',
                        )}
                      >
                        <div className={styl.thumbnail}>
                          <Profile src={cmt.thumb} />
                        </div>
                        <p>{cmt.text}</p>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>

        <div className={styl.giftok}>
          <IconTitle
            icon={<img src={PlayerIcon} alt="player-icon" />}
            title={t('web3.section4.title')}
            desc={isMobile ? t('web3.section4.descM') : t('web3.section4.desc')}
          />

          <div ref={playerRef} className={styl.player}>
            <motion.div className={styl.sticky}>
              <div className={styl.giftokPlayer}>
                <GiftokPlayer containerRef={playerRef} />
              </div>
            </motion.div>
          </div>
        </div>

        <div className={styl.invite}>
          <IconTitle
            icon={<img src={InviteIcon} alt="invite-icon" />}
            title={
              isMobile ? t('web3.section5.titleM') : t('web3.section5.title')
            }
            desc={isMobile ? t('web3.section5.descM') : t('web3.section5.desc')}
          />
          <div className={styl.chatList}>
            <motion.div className={styl.chat} ref={chat1Ref}>
              <div
                className={styl.thumb}
                style={{
                  opacity: text1Show ? 1 : 0,
                  transition: 'opacity 0.35s',
                }}
              >
                <Profile src={ProfileFeed3} />
              </div>
              <div>
                <p
                  className={styl.text}
                  style={{
                    opacity: text1Show ? 1 : 0,
                    transition: 'opacity 0.35s',
                  }}
                >
                  {isMobile ? section5MessagesM[0] : section5Messages[0]}
                </p>

                <motion.p
                  className={styl.text}
                  ref={chat2Ref}
                  style={{
                    opacity: text2Show ? 1 : 0,
                    transition: 'opacity 0.35s',
                  }}
                >
                  {isMobile ? section5MessagesM[1] : section5Messages[1]}
                </motion.p>

                <motion.div
                  className={styl.nft}
                  ref={chat3Ref}
                  style={{
                    opacity: text3Show ? 1 : 0,
                    transition: 'opacity 0.35s',
                  }}
                >
                  <img src={ProfileNFT} alt="" />
                </motion.div>
              </div>
            </motion.div>

            <div
              className={classNames(styl.chat, styl.group)}
              ref={chat4Ref}
              style={{
                opacity: text4Show ? 1 : 0,
                transition: 'opacity 0.35s',
              }}
            >
              <div className={styl.thumb}>
                <Profile src={ProfileFeed2} />
                <Profile src={ProfileFeed4} />
                <Profile src={ProfileFeed5} />
              </div>
              <div>
                <p className={styl.text}>
                  {isMobile ? section5MessagesM[2] : section5Messages[2]}
                </p>
              </div>
            </div>

            <div
              className={styl.chat}
              ref={chat5Ref}
              style={{
                opacity: text5Show ? 1 : 0,
                transition: 'opacity 0.35s',
              }}
            >
              <div className={styl.thumb}>
                <Profile src={ProfileFeed3} />
              </div>
              <div>
                <p className={styl.text}>
                  {isMobile ? section5MessagesM[3] : section5Messages[3]}
                </p>
              </div>
              <div className={styl.lock}>
                <Profile src={LockCircle} />
              </div>
            </div>
          </div>

          <div style={{ height: screenHeight * 2.5 }} ref={inviteRef}>
            <div
              className={classNames(styl.sticky, {
                [styl.transit]: showTransitionText,
              })}
            >
              <div className={styl.innerSticky}>
                <div
                  className={styl.joinPopup}
                  ref={chat6Ref}
                  style={{
                    opacity: text6Show ? 1 : 0,
                    transition: 'opacity 0.35s',
                  }}
                >
                  <div className={styl.profileImg}>
                    <Profile src={ProfileCommaOrange} />
                  </div>
                  <h3>{t('web3.section5.cardTitle')}</h3>
                  <p>68,446 Members</p>
                  <p>{t('web3.section5.cardDesc')}</p>
                  <img src={TicketImg} className={styl.ticket} />
                  <button className={styl.join} disabled>
                    <img src={InviteIcon} />
                    {t('web3.section5.cardButtonLabel')}
                  </button>
                </div>

                <div
                  className={classNames(styl.invitees, {
                    [styl.show]: showInvitee,
                  })}
                >
                  <div
                    className={classNames(styl.invitee, {
                      [styl.checked]: showInviteeCheck,
                    })}
                  >
                    <div className={styl.check}>
                      <img src={GatingCheck} />
                    </div>
                    <Profile src={ProfileFeed2} />
                  </div>

                  <div
                    className={classNames(styl.invitee, {
                      [styl.checked]: showInviteeCheck,
                    })}
                  >
                    <div className={styl.check}>
                      <img src={GatingCheck} />
                    </div>
                    <Profile src={ProfileFeed4} />
                  </div>

                  <div
                    className={classNames(styl.invitee, {
                      [styl.checked]: showInviteeCheck,
                    })}
                  >
                    <div className={styl.check}>
                      <img src={GatingCheck} />
                    </div>
                    <Profile src={ProfileFeed5} />
                  </div>
                </div>

                <div
                  className={classNames(styl.transition, {
                    [styl.show]: showTransitionText,
                  })}
                >
                  <div
                    className={classNames(styl.text, {
                      [styl.hide]: showTransitionText2,
                    })}
                  >
                    {t('web3.section6.startText')}
                  </div>

                  <div
                    className={classNames(styl.text2, {
                      [styl.show]: showTransitionText2,
                    })}
                  >
                    <SectionHeader
                      icon={StoreIcon}
                      title={t('web3.section6.title')}
                      desc={
                        isMobile
                          ? t('web3.section6.descM')
                          : t('web3.section6.desc')
                      }
                    />
                  </div>
                  <motion.div className={styl.bg}>
                    <motion.p
                      style={{
                        paddingLeft: tansitionBgScaleX,
                        paddingRight: tansitionBgScaleX,
                        paddingTop: tansitionBgScaleY,
                        paddingBottom: tansitionBgScaleY,
                        translate: '-50% -50%',
                        maxWidth: '100vw',
                      }}
                    >
                      {t('web3.section6.startText')}
                    </motion.p>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
