import { atom } from 'jotai';
import { detect } from '../utils/detect';
const isSnapshot = navigator.userAgent === 'ReactSnap';

export const initProjectAtom = atom(false);
export const startChangeRouteAtom = atom(false);
export const pageInitAtom = atom(isSnapshot);
export const isMobileAtom = atom(detect());
export const screenWidthAtom = atom(window.innerWidth);
export const screenHeightAtom = atom(window.innerHeight);
