const IMAGE_BASE_PATH_PC = 'home/pc/';
const IMAGE_BASE_PATH_MO = 'home/mobile/';

const section1 = {
  title:
    'Get started with CIETY,\n' +
    'the community platform\n' +
    'for YouTube creators!',
  desc: 'Community Platform for Creators\n' + 'Your World, Your CIETY',
  buttonLabel: 'Create CIETY',
  buttonLabelM: 'Create Community',
  toolTip:
    'Create a CIETY subdomain and\n' + 'build your community right away!',
};

const section2 = {
  title:
    'From newbies to true fans!\n' +
    'A world of fun moments that\n' +
    'naturally deepen connections',
};

const section3 = {
  subTitle: "Let's start a community!",
  title:
    'Starting a community means\n' +
    'gathering your true fans and\n' +
    'being able to connect with them!',
  titleM:
    'Creating a community\n' + 'means connecting\n' + 'with your true fans!',
  desc:
    'Are you still communicating with your fandom through short comments? \n' +
    'Fill your community with various interaction methods, and create content\n' +
    "or products that reflect your fans' needs and ideas!",
  descM:
    'Comments still your only form of fan interaction?\n' +
    "Let's diversify and create content and products that\n" +
    "resonate with the fandom's needs!",
  buttonLabel: 'Get started for free',
};

const section4 = {
  subTitle: 'Hello. Youtube creator',
  title: 'Perfect community for\n' + 'YouTube creators!',
  desc:
    'From simple channel updates to open discussions, paid content sales,\n' +
    'and merchandise sales — what if you had a space to talk with your subscribers?\n' +
    'Gather your true fans on your YouTube channel!',
  descM:
    'Gather your true fans on your YouTube channel\n' +
    'with announcements, casual chats, paid content,\n' +
    'and merchandise sales!',
  buttonLabel: 'Get started for free',
  messages: [
    [
      'Loved the game \n' + 'ou recommended!!!',
      'Live at 7 PM!\n' + 'Join me on CIETY!',
      'Anyone know what time\n' + "today's livestream is?",
      'Excited for your quick\n' + 'review of the new game!',
      'Limited edition goods...\n' + 'Not for resale???...',
    ],
    [
      "Today's live outfit\n" + 'is totally my style!',
      'Gloves and hats\n' + 'merch, please!!!!!!',
      'Live now! Check out\n' + 'our summer t-shirt haul!',
      "Curious about today's makeup!\n" + 'Eyeshadow product details plz.',
      'Community t-shirts merch rocks!\n' + 'Off to buy it now!',
    ],
    [
      'Love the soothing voice,\n' + "it's so calming...",
      'Livestream is the real deal...\n' + 'Ears are melting!',
      'Please open a concert! \n' + "I'll definitely buy tickets..!!",
      "Until when's the live today?\n" + 'Inviting all my friends!!',
      'Song request event happening now.\n' +
        "I'll sing for you and send merch!",
    ],
  ],
  messagesM: [
    [
      'Anyone know what time\n' + "today's livestream is?",
      'Live at 7 PM!\n' + 'Join me on CIETY!',
      'Limited edition goods...\n' + 'Not for resale???...',
    ],
    [
      "Today's live outfit\n" + 'is totally my style!',
      'Live now! Check out\n' + 'our summer t-shirt haul!',
      'Community t-shirts\n' + 'merch rocks!\n' + 'Off to buy it now!',
    ],
    [
      'Livestream is the real deal...\n' + 'Ears are melting!',
      'Please open a concert! \n' + "I'll definitely buy tickets..!!",
      'Song request event now.\n' + "I'll sing for you & send merch!",
    ],
  ],
  images: [
    `${IMAGE_BASE_PATH_PC}EN-Section6-img1.png`,
    `${IMAGE_BASE_PATH_PC}EN-Section6-img2.png`,
    `${IMAGE_BASE_PATH_PC}EN-Section6-img3.png`,
  ],
};

const section5 = {
  subTitle: 'for your Real Fandom',
  title:
    'You can create a community\n' +
    'exclusively for your YouTube\n' +
    'channel subscribers!',
  titleM: 'Exclusive YouTube channel\n' + 'community for subscribers! ',
  desc:
    'CIETY allows you to set community membership conditions\n' +
    'through YouTube account integration.\n' +
    'Of course, you can also create open communities that anyone can join!',
  descM:
    'Set membership criteria via YouTube account\n' +
    'linking or create an open community for all!',
  buttonLabel: 'Get started for free',
  imageLabels: [
    'Non-subscriber',
    'Subscriber',
    'Membership',
    'Power Membership',
  ],
};

const section6 = {
  subTitle: 'from creator to Brand Growth',
  title:
    'From identifying subscriber\n' +
    'needs in my community\n' +
    'to producing and selling merch!',
  titleM: 'Understand subscriber needs,\n' + 'produce merch, and sell!',
  desc:
    'In the diverse range of stories freely shared on CIETY,\n' +
    'you can discover various needs of subscribers.\n' +
    'Get insights from subscriber needs to plan and launch merchandise!\n' +
    'From production to sales and shipping, everything is possible on CIETY!',
  descM:
    'Gain insights from subscriber needs\n' +
    'and sell merchandise from\n' +
    'production to shipping!',
  buttonLabel: 'Get started for free',
};

const section7 = {
  subTitle: 'Exclusive Live Streaming',
  title:
    'Exclusive live streaming\n' +
    'only for the community\n' +
    'members I desire!',
  titleM:
    'Exclusive live streaming\n' +
    'available only for\n' +
    'community members!',
  desc:
    'On CIETY, you can broadcast live streams exclusively for\n' +
    'community membership subscribers or specific members!\n' +
    'Connect more closely with your community members!',
  descM:
    'You can stream live exclusively to community\n' +
    'membership subscribers or specific members!',
  buttonLabel: 'Get started for free',
  messages: [
    'So nice.........',
    'Hearing this song\n' + 'live is so moving...\n',
    "This isn't on the album!!!",
    'Wow, seeing this on CIETY!',
    'waiting for this song!!!!!!',
    "Today's awesome!",
    "Got the tee, it's beautiful!",
    "Live's the best!",
    "You're amazing...",
  ],
};

const section8 = {
  subTitle: 'Contents Commerce in Community',
  title: 'From high-quality paid content\n' + 'to selling online classes!',
  titleM: 'Sell high-quality\n' + 'paid content and classes!',
  desc:
    'Engage with subscribers who like and are interested in you on CIETY.\n' +
    'Produce high-quality content and easily sell online classes—\n' +
    'all within the CIETY community without needing other platforms!',
  descM:
    'Create content and sell online classes.\n' +
    'Everything is possible within the CIETY\n' +
    'community without needing other platforms!',
  buttonLabel: 'Get started for free',
};

const section9 = {
  subTitle: 'Direct Message',
  title:
    'Gift your fans a\n' +
    'deeper sense of belonging\n' +
    'with 1:1 direct messaging!',
  titleM:
    'Gift your fans with a deeper\n' +
    'sense of belonging through\n' +
    '1:1 direct messaging!',
  desc:
    'If members can freely chat with the creators and other members\n' +
    'they want to converse with within the community,\n' +
    'the cohesion of the community will strengthen even more!\n' +
    'Direct message members and creators individually or in groups!',
  descM:
    'Open communication fosters\n' +
    'stronger community bonds! Direct messaging\n' +
    'with members or the creator is essential!',
  buttonLabel: 'Get started for free',
};

const section10 = {
  subTitle: 'Community Member Permission',
  title:
    'Unlimited group creation\n' +
    'and flexible member\n' +
    'permissions in my community!',
  titleM:
    'Create unlimited member\n' +
    'groups with flexible\n' +
    'permission settings!',
  desc:
    'You can manage the community in various groups based on distinctions\n' +
    'like non-subscribers, subscribers, and YouTube channel memberships, \n' +
    'allowing for more detailed management by combining member groups as desired. \n' +
    'Additionally, you can set different permission levels for each group,\n' +
    'enabling special interactions!',
  descM:
    'Set different permissions for various groups in\n' +
    'your community based on subscriber status\n' +
    'and membership levels to facilitate communication\n' +
    'and management!',
  buttonLabel: 'Get started for free',
  circleTaps: ['Non-Sub', 'Subscriber', 'Membership', 'Power-Mem'],
  checkList: [
    'Read, Write Posts',
    'Comments, Emojis',
    'Access to Paid Contents',
    'Access to Limited Merch',
    'Direct Message',
    'View Member List',
    'Livestream',
    'Manager Permissions',
  ],
};

const section11 = {
  title: 'The community you need,\n' + 'Start now with CIETY!',
  titleM: 'The community you need,\n' + 'Start now with CIETY!',
  desc: 'Community Platform for Creators\n' + 'Your World, Your CIETY',
  buttonLabel: 'Create CIETY',
  cards: [
    {
      title: 'Community\n' + 'Design Custom',
      desc:
        'Community with personalized profile, cover,\n' +
        'and color options—your identity at a glance!',
    },
    {
      title: 'Create Community\n' + 'Membership Conditions',
      desc:
        'From exclusive subscriber-only communities\n' +
        'linked to YouTube accounts to open communities\n' +
        'available to all',
    },
    {
      title: 'Merch Production\n' + 'and Sales',
      desc: 'Can create and sell\n' + 'community-related merch',
    },
    {
      title: 'Live Stream',
      desc:
        'Live streaming for community members or\n' +
        'specific member groups (simultaneous\n' +
        'YouTube streaming available)',
    },
    {
      title: 'Paid\n' + 'Content Sales',
      desc: 'Sell paid content to community\n' + 'members or specific groups\n',
    },
    {
      title: 'Unlimited\nCommunication',
      desc:
        'Text, images, videos, emojis, links,\n' +
        'NFTs—no format restrictions',
    },
    {
      title: 'Direct\n' + 'Message',
      desc:
        'Direct messaging to connect subscribers,\n' +
        'creators, or selected member groups',
    },
    {
      title: 'Flexible Member/\n' + 'Group Management',
      desc:
        'Create and manage member groups freely,\n' +
        'with detailed permission settings for each group',
    },
    {
      title: 'YouTube Channel\nVideo Migration',
      desc:
        'Link your YouTube account to automatically\n' +
        'upload your channel videos to the community\n' +
        'for seamless integration',
    },
  ],
};

const section12 = {
  title: 'CIETY, a community\n' + 'platform like no other',
  desc: 'CIETY continuously enhances its features to support a wide range of creators.',
  descM:
    'CIETY continuously enhances its\n' +
    'features to support a wide range of creators.',
};

const section13 = {
  title: 'With CIETY\n' + 'Anytime, Anywhere!',
  desc: 'Community Platform for Creators\n' + 'Your World, Your CIETY',
  ios: { title: 'iOS', label: 'App Store Download' },
  android: { title: 'Android', label: 'Google Play Download' },
  browser: { title: 'Web Browser', label: 'Open at Web Browser' },
  deskApp: { title: 'Desk App', label: 'Over MacOS 10.13 (High Sierra)' },
  buttonLabel: 'Create CIETY',
};

export default {
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
  section10,
  section11,
  section12,
  section13,
};
