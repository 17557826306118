import { useTranslation } from 'react-i18next';
import { motion, useMotionValue, useSpring } from 'framer-motion';
import styles from '../Web3/styles.module.scss';
import { useAtom } from 'jotai';
import { isMobileAtom, startChangeRouteAtom } from '../../logics/atom';

interface IntroProps {
  useTransit?: boolean;
}

function IntroContent(props: IntroProps) {
  const { t } = useTranslation();
  const { useTransit } = props;
  const [isMobile] = useAtom(isMobileAtom);
  const springValue = useSpring(-100);

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}></div>

      <div className={styles.text}>
        {isMobile || useTransit ? (
          <>
            <p>YOUR WORLD</p>
            <p>YOUR CIETY</p>
            <p>CIETY.XYZ</p>
          </>
        ) : (
          <>
            <motion.p
              style={{
                rotate: 10,
                y: springValue,
                originX: -0.9,
                originY: 0,
                opacity: 0,
              }}
              transition={{ duration: 0.5, delay: 0.1 }}
              animate={{ y: 0, rotate: 0, opacity: 1 }}
            >
              YOUR WORLD
            </motion.p>
            <motion.p
              style={{
                rotate: 10,
                y: springValue,
                originX: -0.9,
                originY: 0,
                opacity: 0,
              }}
              transition={{ duration: 0.5, delay: 0.3 }}
              animate={{ y: 0, rotate: 0, opacity: 1 }}
            >
              YOUR CIETY
            </motion.p>
            <motion.p
              style={{
                rotate: 10,
                y: springValue,
                originX: -0.9,
                originY: 0,
                opacity: 0,
              }}
              transition={{ duration: 0.5, delay: 0.6 }}
              animate={{ y: 0, rotate: 0, opacity: 1 }}
            >
              CIETY.XYZ
            </motion.p>
          </>
        )}
      </div>
    </div>
  );
}
export default function Intro(props: IntroProps) {
  const { useTransit } = props;
  const [change] = useAtom(startChangeRouteAtom);
  const [isMobile] = useAtom(isMobileAtom);
  const springValue = useSpring(useMotionValue('0%'));

  return useTransit ? (
    <motion.div
      className={styles.intro}
      style={{ y: change ? '-100%' : '0', zIndex: 1000 }}
      animate={{ y: change ? '0' : '-100%' }}
      transition={{ duration: 0.1, ease: 'easeInOut', delay: 0.35 }}
    >
      <IntroContent />
    </motion.div>
  ) : isMobile ? (
    <motion.div
      className={styles.intro}
      style={{ y: springValue, zIndex: 1000 }}
      animate={{ y: '-100%', borderRadius: '0 0 30px 30px' }}
      transition={{ duration: 0.7, ease: 'easeIn', delay: 1 }}
    >
      <IntroContent />
    </motion.div>
  ) : (
    <div className={styles.intro}>
      <IntroContent />
    </div>
  );
}
