import React, { useEffect, useLayoutEffect } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { ReactGA } from '../../utils/ga';
import { setValues } from '../../utils/func';

import {
  initProjectAtom,
  isMobileAtom,
  screenHeightAtom,
  startChangeRouteAtom,
} from '../../logics/atom';

export default function Layout() {
  const location = useLocation();

  const [isMobile] = useAtom(isMobileAtom);
  const [init, setInit] = useAtom(initProjectAtom);
  const [, setChange] = useAtom(startChangeRouteAtom);
  const [, setIsMobile] = useAtom(isMobileAtom);
  const [, setScreenHeight] = useAtom(screenHeightAtom);
  const handleChangeRouteState = () => {
    if (!init) return;
    setChange(true);
    const timeout = setTimeout(() => {
      setChange(false);
      clearTimeout(timeout);
    }, 500);
  };

  const updateGA = () => {
    ReactGA.pageview(location.pathname + location.search);
  };

  useEffect(() => {
    handleChangeRouteState();
    updateGA();
  }, [location.pathname]);

  useLayoutEffect(() => {
    setInit(true);
    const handleResize = () => {
      if (isMobile) return;
      setIsMobile(window.innerWidth <= 768);
      setScreenHeight(window.innerHeight);
      setValues();
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="mx-auto">
      <ScrollRestoration />
      <Outlet />
    </div>
  );
}
