const IMAGE_BASE_PATH_PC = 'creator/pc/';
const IMAGE_BASE_PATH_MO = 'creator/mobile/';

const section1 = {
  title: '어디에서도 본 적 없던,\n크리에이터를 위한\n커뮤니티 플랫폼 사이어티',
  desc: 'Community Platform for Creators\nYour World, Your CIETY',
  buttonLabel: '커뮤니티 만들기',
};
const section2 = {
  subTitle: 'All-in-one Community Platform',
  title: '내 모든 소식을 한 번에\n커뮤니티 멤버들에게 알리세요!',
  titleM: '내 모든 소식을 한 번에\n커뮤니티 멤버들에게!',
  desc:
    '유튜브 채널 마이그레이션부터 내 팬들을 위한 굿즈 판매,\n' +
    '클래스 오픈, 이벤트, 공지 게시글까지 —\n' +
    '중요한 이벤트와 콘텐츠를 한곳에 알릴 수 있다면 얼마나 좋을까요?',
  descM:
    '유튜브 채널 연동부터 내 팬들을 위한 굿즈 판매,\n' +
    '클래스 오픈, 이벤트, 공지 게시글까지 —\n' +
    '이벤트와 콘텐츠를 한곳에 알리면 얼마나 좋을까요?',
  buttonLabel: 'Get started for free',
  cards: [
    {
      title: '모든 SNS 업로드를\n내 커뮤니티로!',
      desc:
        '유튜브 채널이나 인스타그램, X(트위터), 페이스북 등에\n' +
        '널려있는 내 콘텐츠를 사이어티 한 곳에서 아카이브하고\n' +
        '커뮤니티 멤버들에게 한 번에 알릴 수 있어요.',
      descM:
        '유튜브, 인스타그램, X(트위터), 페이스북 등에 널려있는 콘텐츠를 사이어티 한 곳에 아카이브하세요.',
      images: [`${IMAGE_BASE_PATH_PC}Section2-img1.png`],
    },
    {
      title: '크리에이터에서\n브랜드로!',
      desc:
        '커뮤니티 멤버들을 위한 특별한 굿즈를 제작 / 판매하여\n' +
        '수익을 창출해 보세요. 커뮤니티 멤버나 고마운 멤버십 그룹을 위한\n' +
        '유료 콘텐츠, 클래스 채널도 제작 / 판매가 가능합니다.',
      descM:
        '특별한 굿즈를 제작 / 판매하여 수익을 창출해 보세요. 멤버십을 위한 유료 콘텐츠, 클래스 판매가 가능해요.',
      images: [
        `${IMAGE_BASE_PATH_PC}Section2-img2-1.png`,
        `${IMAGE_BASE_PATH_PC}Section2-img2-2.png`,
      ],
    },
    {
      title: '내 모든 팬들을\n하나의 커뮤니티로!',
      desc:
        '파편 된 내 팬들을 사이어티 커뮤니티 한곳에서 관리해 보세요.\n' +
        '유튜브 계정 연동으로 내 채널 구독자만 입장 가능한 커뮤니티부터\n' +
        '누구나 가입할 수 있는 오픈형 커뮤니티까지 만들어 보세요.',
      descM:
        '유튜브 구독자만 입장 가능 or 누구나 가입할 수 있는 오픈형 커뮤니티까지 만들어 보세요.',
      images: [`${IMAGE_BASE_PATH_PC}Section2-img3.png`],
    },
  ],
};
const section3 = {
  subTitle: 'Contents Migrations',
  title:
    '내 유튜브 채널에 \n' +
    '영상을 업로드하면,\n' +
    '내 사이어티 커뮤니티에도 \n' +
    '자동으로 공유돼요!',
  titleM: '유튜브에 영상을 업로드하면\n' + '커뮤니티에도 자동으로 공유!',
  desc:
    '유튜브, 인스타그램, 페이스북, X(구 트위터)..\n' +
    '내가 다양한 플랫폼에 업로드한 콘텐츠를 사이어티 한곳에 모아보세요.\n' +
    '내 커뮤니티 멤버들은 내 콘텐츠를 한 번에 확인할 수 있어요.',
  descM:
    '유튜브, 인스타그램, 페이스북, X(구 트위터)..\n' +
    '다양한 플랫폼에 업로드한 콘텐츠를\n' +
    '사이어티 커뮤니티에서 한 번에 확인할 수 있어요.',
  buttonLabel: 'Get started for free',
  image: `${IMAGE_BASE_PATH_PC}Section3.png`,
  imagesM: [
    `${IMAGE_BASE_PATH_MO}Section3-img01.png`,
    `${IMAGE_BASE_PATH_MO}Section3-img02.png`,
    `${IMAGE_BASE_PATH_MO}Section3-img03.png`,
    `${IMAGE_BASE_PATH_MO}Section3-img04.png`,
    `${IMAGE_BASE_PATH_MO}Section3-img05.png`,
  ],
};
const section4 = {
  subTitle: '',
  title:
    '팬미팅 티켓부터 굿즈 상품,\n' +
    '내가 만든 상품까지 — \n' +
    '내 커뮤니티에서 모두 판매해 보세요!',
  titleM: '팬미팅 티켓부터 굿즈까지 — \n' + '커뮤니티에서 판매해 보세요!',
  desc:
    '국내 최초 유튜브 쇼핑 파트너, 크리에이터 커머스 플랫폼 마플샵과 연동하여\n' +
    '다양한 굿즈나 상품을 판매하여 수익을 실현해 보세요.\n' +
    '굿즈 제작부터 판매, 배송까지! 사이어티에선 모두 다 가능해요!',
  descM:
    '유튜브 쇼핑 파트너, 크리에이터 커머스 마플샵과\n' +
    '상품 판매로 수익을 실현해 보세요.\n' +
    '굿즈 제작부터 판매, 배송까지! 모두 가능해요!',
  buttonLabel: 'Get started for free',
  marqueeItems: [
    {
      category: '굿즈',
      name: '아크릴 키링',
    },
    {
      category: '폰액세서리',
      name: '한정판 폰케이스',
    },
    {
      category: '의류',
      name: '단체 게이밍 후드',
    },
    {
      category: '문화/공연',
      name: '팬미팅 티켓',
    },
    {
      category: '패션잡화',
      name: '블랙 토트백',
    },
    {
      category: '포스트',
      name: '커뮤니티 포스터',
    },
    {
      category: '문구/오피스',
      name: '커뮤니티 기록 노트',
    },
    {
      category: '의류',
      name: '반소매 티셔츠',
    },
    {
      category: '폰액세서리',
      name: '커뮤니티 스마트톡',
    },
  ],
};
const section5 = {
  subTitle: 'Community Channel Setting',
  title:
    '내가 원하는대로,\n' +
    '자유롭게 커뮤니티 채널을\n' +
    '커스텀 할 수 있어요!',
  titleM: '자유롭게 커뮤니티 채널을\n' + '커스텀 할 수 있어요!',
  desc:
    '일반적인 이미지와 텍스트, 영상을 게시할 수 있는 포스트 채널부터\n' +
    '채팅 채널, 라이브 채널까지. 퍼블릭 한 공간부터 프라이빗 한 공간까지\n' +
    '자유롭게 나만의 커뮤니티를 만들어보세요!',
  descM:
    '일반적인 포스트부터 채팅, 라이브 채널까지.\n' +
    '퍼블릭 한 공간부터 프라이빗 한 공간까지 자유롭게!',
  buttonLabel: 'Get started for free',
  image: `${IMAGE_BASE_PATH_PC}Section5.png`,
  imageM: `${IMAGE_BASE_PATH_MO}Section5.png`,
};
const section6 = {
  subTitle: 'Live Streaming',
  title: '라이브 스트리밍으로\n' + '멤버들과 실시간으로 소통하세요!',
  desc:
    '라이브 스트리밍 방송을 진행하면서 멤버들과 실시간으로 나누는 채팅,\n' +
    '그리고 방송 종료 이후 영상과 채팅은 그대로 아카이브 됩니다.\n' +
    '유튜브 라이브와 동시 송출도 가능해요!',
  descM:
    '라이브 스트리밍으로 멤버들과 실시간 채팅,\n' +
    '방송 영상과 채팅은 그대로 아카이브 되고\n' +
    '유튜브 라이브와 동시 송출도 가능해요!',
  buttonLabel: 'Get started for free',
  images: [
    `${IMAGE_BASE_PATH_PC}Section6-img1.png`,
    `${IMAGE_BASE_PATH_PC}Section6-img2.png`,
    `${IMAGE_BASE_PATH_PC}Section6-img3.png`,
    `${IMAGE_BASE_PATH_PC}Section6-img4.png`,
    `${IMAGE_BASE_PATH_PC}Section6-img5.png`,
  ],
};
const section7 = {
  subTitle: 'Member Permission Setting',
  title:
    '섬세한 멤버 권한 설정으로\n' +
    '쉽고 편하게 커뮤니티를\n' +
    '관리해 보세요!',
  titleM: '섬세한 멤버 권한 설정으로\n' + '쉽고 편하게 관리하세요!',
  desc:
    '내 유튜브 채널 구독자부터 멤버십 회원, 그리고 비구독자까지. \n' +
    '내 커뮤니티의 모든 멤버들에게 다양한 권한을 ON/OFF 설정하여\n' +
    '보다 쉽고 편리하게 커뮤니티를 관리할 수 있어요!',
  descM:
    '커뮤니티의 모든 멤버들의 권한을 ON/OFF 로\n' +
    '쉽고 편리하게 커뮤니티를 관리할 수 있어요!',
  buttonLabel: 'Get started for free',
  image: `${IMAGE_BASE_PATH_PC}Section7.png`,
  imageM: `${IMAGE_BASE_PATH_MO}Section7.png`,
};
const section8 = {
  subTitle: 'Content Commerce Community',
  title: '유료 클래스를 제작하고,\n' + '쉽게 콘텐츠를 판매해 보세요!',
  titleM: '유료 클래스 제작하고,\n콘텐츠를 판매해 보세요!',
  desc:
    '사이어티 커뮤니티에서는 유료 멤버십을 위해 다양한 콘텐츠를 제공할 수 있어요.\n' +
    '멤버십만 볼 수 있는 콘텐츠, 클래스를 오픈할 수 있고\n' +
    '사이어티 커뮤니티의 멤버십을 만들어 콘텐츠를 더 자유롭게 판매할 수 있어요!',
  descM:
    '멤버십 콘텐츠, 유료 클래스, \n' +
    '사이어티 커뮤니티의 멤버십을 판매하세요!',
  buttonLabel: 'Get started for free',
  images: [
    `${IMAGE_BASE_PATH_PC}Section8-img3.png`,
    `${IMAGE_BASE_PATH_PC}Section8-img1.png`,
    `${IMAGE_BASE_PATH_PC}Section8-img2.png`,
  ],
};
const section9 = {
  subTitle: 'CIETY GIFTOK',
  title: '내 커뮤니티에서\n' + '기프톡을 받아보세요!',
  desc:
    '내 커뮤니티 멤버들에게 크리에이터를 위한 응원으로 기프톡을 받을 수 있어요.\n' +
    '사이어티 기프톡은 커뮤니티 전체 수익과 함께 정산 출금할 수 있어요.',
  descM:
    '커뮤니티 멤버들에게 응원의 기프톡을 받을 수 있어요.\n' +
    '커뮤니티 전체 수익과 함께 정산 출금할 수 있어요.',
  buttonLabel: 'Get started for free',
  image: `${IMAGE_BASE_PATH_PC}Section9.png`,
  imageM: `${IMAGE_BASE_PATH_MO}Section9.png`,
};

export default {
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
};
