import Seq00 from './S01-Comma-02_00000_50.webp';
import Seq01 from './S01-Comma-02_00001_50.webp';
import Seq02 from './S01-Comma-02_00002_50.webp';
import Seq03 from './S01-Comma-02_00003_50.webp';
import Seq04 from './S01-Comma-02_00004_50.webp';
import Seq05 from './S01-Comma-02_00005_50.webp';
import Seq06 from './S01-Comma-02_00006_50.webp';
import Seq07 from './S01-Comma-02_00007_50.webp';
import Seq08 from './S01-Comma-02_00008_50.webp';
import Seq09 from './S01-Comma-02_00009_50.webp';
import Seq10 from './S01-Comma-02_00010_50.webp';
import Seq11 from './S01-Comma-02_00011_50.webp';
import Seq12 from './S01-Comma-02_00012_50.webp';
import Seq13 from './S01-Comma-02_00013_50.webp';
import Seq14 from './S01-Comma-02_00014_50.webp';
import Seq15 from './S01-Comma-02_00015_50.webp';
import Seq16 from './S01-Comma-02_00016_50.webp';
import Seq17 from './S01-Comma-02_00017_50.webp';
import Seq18 from './S01-Comma-02_00018_50.webp';
import Seq19 from './S01-Comma-02_00019_50.webp';
import Seq20 from './S01-Comma-02_00020_50.webp';
import Seq21 from './S01-Comma-02_00021_50.webp';
import Seq22 from './S01-Comma-02_00022_50.webp';
import Seq23 from './S01-Comma-02_00023_50.webp';
import Seq24 from './S01-Comma-02_00024_50.webp';
import Seq25 from './S01-Comma-02_00025_50.webp';
import Seq26 from './S01-Comma-02_00026_50.webp';
import Seq27 from './S01-Comma-02_00027_50.webp';
import Seq28 from './S01-Comma-02_00028_50.webp';
import Seq29 from './S01-Comma-02_00029_50.webp';
import Seq30 from './S01-Comma-02_00030_50.webp';
import Seq31 from './S01-Comma-02_00031_50.webp';
import Seq32 from './S01-Comma-02_00032_50.webp';
import Seq33 from './S01-Comma-02_00033_50.webp';
import Seq34 from './S01-Comma-02_00034_50.webp';
import Seq35 from './S01-Comma-02_00035_50.webp';
import Seq36 from './S01-Comma-02_00036_50.webp';
import Seq37 from './S01-Comma-02_00037_50.webp';
import Seq38 from './S01-Comma-02_00038_50.webp';
import Seq39 from './S01-Comma-02_00039_50.webp';
import Seq40 from './S01-Comma-02_00040_50.webp';
import Seq41 from './S01-Comma-02_00041_50.webp';
import Seq42 from './S01-Comma-02_00042_50.webp';
import Seq43 from './S01-Comma-02_00043_50.webp';
import Seq44 from './S01-Comma-02_00044_50.webp';
import Seq45 from './S01-Comma-02_00045_50.webp';
import Seq46 from './S01-Comma-02_00046_50.webp';
import Seq47 from './S01-Comma-02_00047_50.webp';
import Seq48 from './S01-Comma-02_00048_50.webp';
import Seq49 from './S01-Comma-02_00049_50.webp';
import Seq50 from './S01-Comma-02_00050_50.webp';
import Seq51 from './S01-Comma-02_00051_50.webp';
import Seq52 from './S01-Comma-02_00052_50.webp';
import Seq53 from './S01-Comma-02_00053_50.webp';
import Seq54 from './S01-Comma-02_00054_50.webp';
import Seq55 from './S01-Comma-02_00055_50.webp';
import Seq56 from './S01-Comma-02_00056_50.webp';
import Seq57 from './S01-Comma-02_00057_50.webp';
import Seq58 from './S01-Comma-02_00058_50.webp';
import Seq59 from './S01-Comma-02_00059_50.webp';
import Seq60 from './S01-Comma-02_00060_50.webp';
import Seq61 from './S01-Comma-02_00061_50.webp';
import Seq62 from './S01-Comma-02_00062_50.webp';
import Seq63 from './S01-Comma-02_00063_50.webp';
import Seq64 from './S01-Comma-02_00064_50.webp';
import Seq65 from './S01-Comma-02_00065_50.webp';
import Seq66 from './S01-Comma-02_00066_50.webp';
import Seq67 from './S01-Comma-02_00067_50.webp';
import Seq68 from './S01-Comma-02_00068_50.webp';
import Seq69 from './S01-Comma-02_00069_50.webp';
import Seq70 from './S01-Comma-02_00070_50.webp';
import Seq71 from './S01-Comma-02_00071_50.webp';
import Seq72 from './S01-Comma-02_00072_50.webp';
import Seq73 from './S01-Comma-02_00073_50.webp';
import Seq74 from './S01-Comma-02_00074_50.webp';
import Seq75 from './S01-Comma-02_00075_50.webp';
import Seq76 from './S01-Comma-02_00076_50.webp';
import Seq77 from './S01-Comma-02_00077_50.webp';
import Seq78 from './S01-Comma-02_00078_50.webp';

export const CommaTurnSeq: Array<string> = [
  Seq00,
  Seq01,
  Seq02,
  Seq03,
  Seq04,
  Seq05,
  Seq06,
  Seq07,
  Seq08,
  Seq09,
  Seq10,
  Seq11,
  Seq12,
  Seq13,
  Seq14,
  Seq15,
  Seq16,
  Seq17,
  Seq18,
  Seq19,
  Seq20,
  Seq21,
  Seq22,
  Seq23,
  Seq24,
  Seq25,
  Seq26,
  Seq27,
  Seq28,
  Seq29,
  Seq30,
  Seq31,
  Seq32,
  Seq33,
  Seq34,
  Seq35,
  Seq36,
  Seq37,
  Seq38,
  Seq39,
  Seq40,
  Seq41,
  Seq42,
  Seq43,
  Seq44,
  Seq45,
  Seq46,
  Seq47,
  Seq48,
  Seq49,
  Seq50,
  Seq51,
  Seq52,
  Seq53,
  Seq54,
  Seq55,
  Seq56,
  Seq57,
  Seq58,
  Seq59,
  Seq60,
  Seq61,
  Seq62,
  Seq63,
  Seq64,
  Seq65,
  Seq66,
  Seq67,
  Seq68,
  Seq69,
  Seq70,
  Seq71,
  Seq72,
  Seq73,
  Seq74,
  Seq75,
  Seq76,
  Seq77,
  Seq78,
];
