const IMAGE_BASE_PATH_PC = 'creator/pc/';
const IMAGE_BASE_PATH_MO = 'creator/mobile/';

const section1 = {
  title: 'Community platform\n' + 'like no other, for creators\n' + 'CIETY',
  desc: 'Community Platform for Creators\n' + 'Your World, Your CIETY',
  buttonLabel: 'Create CIETY',
};
const section2 = {
  subTitle: 'All-in-one Community Platform',
  title: 'Let me share all my news with\n' + 'the community members at once!',
  titleM:
    'Share all my updates with\n' + 'the community members\n' + 'at once!',
  desc:
    'From YouTube channel migration to merchandise sales for my fans,\n' +
    'class openings, events, and announcement posts —\n' +
    "wouldn't it be great to announce important events and content all in one place?",
  descM:
    'Linking YouTube, selling merchandise,\n' +
    'opening classes, posting events, & announcements\n' +
    '—all in one place for seamless promotion!',
  buttonLabel: 'Get started for free',
  cards: [
    {
      title: 'Share all my SNS uploads\n' + 'with my community!',
      desc:
        "Let's archive all the content scattered across YouTube\n" +
        'channels, Instagram, X, Facebook, and more in one place,\n' +
        'and notify members all at once!',
      descM:
        "Let's archive all the content scattered across YouTube channels, Instagram, X, Facebook, and more in one place, and notify members all at once!",
      images: [`${IMAGE_BASE_PATH_PC}Section2-img1.png`],
    },
    {
      title: 'Creator to\n' + 'Brand!',
      desc:
        'We can create and sell unique merchandise for \n' +
        'community members and offer paid content or\n' +
        'classes for valued members or membership groups.',
      descM:
        'We can create and sell unique merchandise for community members and offer paid content or classes for valued members or membership groups.',
      images: [
        `${IMAGE_BASE_PATH_PC}EN-Section2-img2-1.png`,
        `${IMAGE_BASE_PATH_PC}EN-Section2-img2-2.png`,
      ],
    },
    {
      title: 'Bringing all my fans\n' + 'into 1 Community!',
      desc:
        'Manage all my fans in 1 place. Create exclusive\n' +
        'communities for YouTube subscribers or open\n' +
        'communities for everyone.',
      descM:
        'Manage all my fans in 1 place.\n' +
        'Create exclusive communities for YouTube subscribers or open communities for everyone.',
      images: [`${IMAGE_BASE_PATH_PC}Section2-img3.png`],
    },
  ],
};
const section3 = {
  subTitle: 'Contents Migrations',
  title:
    'Videos uploaded to\n' +
    'your YouTube channel\n' +
    'automatically share to\n' +
    'your CIETY!',
  titleM:
    'Automatically share videos\n' +
    'uploaded to YouTube\n' +
    'with the community!',
  desc:
    'Aggregate content from YouTube, Instagram, Facebook, X, etc.,\n' +
    'into one place on CIETY for easy access by community members!',
  descM:
    'You can easily view content uploaded to\n' +
    'various social media platforms\n' +
    'in the CIETY community.',
  buttonLabel: 'Get started for free',
  image: `${IMAGE_BASE_PATH_PC}EN-Section3.png`,
  imagesM: [
    `${IMAGE_BASE_PATH_MO}EN-Section3-img01.png`,
    `${IMAGE_BASE_PATH_MO}EN-Section3-img02.png`,
    `${IMAGE_BASE_PATH_MO}EN-Section3-img03.png`,
    `${IMAGE_BASE_PATH_MO}EN-Section3-img04.png`,
    `${IMAGE_BASE_PATH_MO}EN-Section3-img05.png`,
  ],
};
const section4 = {
  subTitle: '',
  title:
    'Sell everything from fan meeting tickets to\n' +
    "merchandise and products I've created\n" +
    'right in my community!',
  titleM:
    'Sell everything from\n' +
    'fan meeting tickets\n' +
    'to merch in the community!',
  desc:
    'Connect with MarppleShop, the first YouTube shopping partner in Korea,\n' +
    'and the creator commerce platform to sell a variety of goods and products,\n' +
    'from creation to sale and delivery! Everything is possible on CIETY!',
  descM:
    'Generate revenue through YouTube\n' +
    'Shopping Partner, Creator Commerce\n' +
    'MarppleShop, and merchandise sales.\n' +
    'Everything from creation to shipping is possible!',
  buttonLabel: 'Get started for free',
  marqueeItems: [
    {
      category: 'Goods',
      name: 'Acrylic Key-ring',
    },
    {
      category: 'Phone Acc',
      name: 'Phonecase',
    },
    {
      category: 'Apparel',
      name: 'Gaming Hood',
    },
    {
      category: 'Culture',
      name: 'Fan Meeting Ticket',
    },
    {
      category: 'Fashion Acc',
      name: 'Black Tote',
    },
    {
      category: 'Poster',
      name: 'Community Poster',
    },
    {
      category: 'Stationery',
      name: 'Community Note',
    },
    {
      category: 'Apparel',
      name: 'Community Tshirts',
    },
    {
      category: 'Phone Acc',
      name: 'Smart Tok',
    },
  ],
};
const section5 = {
  subTitle: 'Community Channel Setting',
  title:
    "You're free to customize\n" + 'your community channel\n' + 'as you like!',
  titleM: 'Customize your\n' + 'community channel freely!',
  desc:
    'From standard posts for images, text, and videos to chat channels\n' +
    'and live streams, you can create your own community ranging from\n' +
    'public to private spaces, all according to your preferences!',
  descM:
    'From regular posts to chats and live channels.\n' +
    'From public spaces to private ones,\n' +
    'the possibilities are endless!',
  buttonLabel: 'Get started for free',
  image: `${IMAGE_BASE_PATH_PC}EN-Section5.png`,
  imageM: `${IMAGE_BASE_PATH_MO}EN-Section5.png`,
};
const section6 = {
  subTitle: 'Live Streaming',
  title:
    "You're free to customize\n" + 'your community channel\n' + 'as you like!',
  desc:
    'Chat live with members while broadcasting, and after the stream ends,\n' +
    'both the video and chat are archived. You can also simultaneously broadcast to YouTube Live!',
  descM:
    'With live streaming, chat with members in\n' +
    'real-time, archive broadcasts and chat,\n' +
    'and simultaneously stream with YouTube Live!',
  buttonLabel: 'Get started for free',
  images: [
    `${IMAGE_BASE_PATH_PC}EN-Section6-img1.png`,
    `${IMAGE_BASE_PATH_PC}EN-Section6-img2.png`,
    `${IMAGE_BASE_PATH_PC}EN-Section6-img3.png`,
    `${IMAGE_BASE_PATH_PC}EN-Section6-img4.png`,
    `${IMAGE_BASE_PATH_PC}EN-Section6-img5.png`,
  ],
};
const section7 = {
  subTitle: 'Member Permission Setting',
  title: 'Engage with members in\n' + 'real-time through live streaming! ',
  titleM:
    'Easily manage your\n' + 'community with precise\n' + 'member permission!',
  desc:
    'Chat live with members while broadcasting, and after the stream ends,\n' +
    'both the video and chat are archived. You can also simultaneously broadcast to YouTube Live!',
  descM:
    'With simple ON/OFF controls,\n' +
    'you can easily manage all\n' +
    "members' permissions in the community!",
  buttonLabel: 'Get started for free',
  image: `${IMAGE_BASE_PATH_PC}EN-Section7.png`,
  imageM: `${IMAGE_BASE_PATH_MO}EN-Section7.png`,
};
const section8 = {
  subTitle: 'Content Commerce Community',
  title:
    'Easily manage\n' +
    'your community with\n' +
    'precise member\n' +
    'permission settings!',
  titleM: 'Create paid classes\n' + 'and sell content!',
  desc:
    'From YouTube channel subscribers to membership members,\n' +
    'and even non-subscribers. Easily manage your community\n' +
    'by toggling various permissions ON/OFF for all members!',
  descM:
    'Sell membership content, paid classes,\n' +
    'and memberships in the CIETY community!',
  buttonLabel: 'Get started for free',
  images: [
    `${IMAGE_BASE_PATH_PC}EN-Section8-img1.png`,
    `${IMAGE_BASE_PATH_PC}EN-Section8-img2.png`,
    `${IMAGE_BASE_PATH_PC}EN-Section8-img3.png`,
  ],
};
const section9 = {
  subTitle: 'CIETY GIFTOK',
  title: 'Receive Giftok\n' + 'in my community!',
  desc:
    'Community members can receive Giftok to support creators.\n' +
    'CIETY Giftok can be withdrawn along with\n' +
    'the overall community earnings.',
  descM:
    'Community members can receive\n' +
    'Giftok to support creators.\n' +
    'CIETY Giftok can be withdrawn along with\n' +
    'the overall community earnings.',
  buttonLabel: 'Get started for free',
  image: `${IMAGE_BASE_PATH_PC}EN-Section9.png`,
  imageM: `${IMAGE_BASE_PATH_MO}EN-Section9.png`,
};

export default {
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
};
