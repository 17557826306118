export default {
  title: 'Let me tell you more\n' + 'about CIETY',
  questions: [
    {
      question: 'I want to create a community! Can I receive 1:1 consultation?',
      answer:
        'If you\'re looking to create a community and need some 1:1 consultation, you can reach out to us at <a href="mailto:contact@ciety.io">contact@ciety.io</a>\n' +
        'Just provide your name, contact information, social links, and any questions you have about setting up your community.\n\n' +
        'The CIETY team is always ready to help you get started.',
    },
    {
      question: "Can I create a community even if I'm not a YouTube creator?",
      answer:
        'Yes! Anyone can easily create a community on CIETY.\n\n' +
        'Share study assignments with school friends, create a favorite game guild to play games together via\n' +
        'live streaming, or establish a hobby club community to organize events on topics of interest.\n\n' +
        "Even if you're not a YouTube creator, if you're active as a creator on platforms like Instagram, X (formerly Twitter), \n" +
        'TikTok, or Facebook, you can create a community on CIETY. Share everything you want on CIETY, \n' +
        'from posts and photos to videos, live streams, merchandise sales, and paid class sales.',
    },
    {
      question: 'Why is CIETY a community platform for YouTube creators?',
      answer:
        'CIETY enables you to create an official community for your YouTube channel linked to CIETY.\n' +
        'Communities linked to YouTube channels through CIETY can utilize the following features:\n' +
        '<ol><li>You can verify YouTube channel subscribers and set community membership conditions so that only subscribers can join the community.</li>' +
        '<li>Create member groups based on channel subscribers and membership levels, allowing you to set permissions for specific groups for posting, chatting, and live streaming within the community.</li>' +
        '<li>When you upload videos to your YouTube channel, they are automatically linked and shared in the CIETY community, allowing you to promote video content to community members and archive it.</li>' +
        "<li>Integrate YouTube shopping to showcase and sell products available in your YouTube channel's Maple Shop in the CIETY community as well.</li></ol>" +
        "In addition to these features, CIETY continuously enhances its community platform to cater to various creators' needs.\n" +
        'If you encounter any inconvenience or have suggestions for improvement while using the CIETY community, please feel free to contact us at <a href="mailto:contact@ciety.io">contact@ciety.io</a>',
    },
    {
      question: 'Can I create multiple communities with one CIETY account?',
      answer:
        'Certainly! With CIETY, you can create multiple communities using just one account.' +
        '<ul><li>Explore various communities such as hobby clubs, work study groups, school project teams, local communities, fan clubs, and more.</li>' +
        '<li>Enhance your community by creating posting channels for sharing text, photos, and videos, chat channels, and live stream channels.</li>' +
        '<li>Manage your community more effectively by setting different permissions for roles within the community.</li></ul>' +
        'CIETY offers unlimited community creation, unlimited channel creation,\n' +
        'and unlimited member group creation, providing a highly flexible community platform experience.',
    },
    {
      question: "I'd like to know more about community channel settings!",
      answer:
        'In CIETY communities, a "channel" refers to a platform where you can upload posts, engage in chats,\n' +
        'or conduct live streams, similar to a bulletin board. In CIETY community channel settings, you can configure the following features:' +
        '<ul><li>Select channel type</li>' +
        '<li>Set channel name and description/li>' +
        '<li>Choose channel visibility: Public / Restricted / Private</li>' +
        '<li>Define channel permissions: Set permissions for selected member groups</li></ul>' +
        'CIETY communities offer three types of channels: post channels with a post editor, chat channels,\n' +
        'and live stream channels. With unlimited channel creation,\n' +
        'you have the freedom to create and manage diverse communities where various discussions can take place.',
    },
    {
      question: "I'd like to know more about community group settings!",
      answer:
        'In CIETY communities, you can create diverse member groups without limitations and assign permissions with precision.\n' +
        'Within the community, all members can belong to one or multiple groups, or even none.\n\n' +
        "Here's what you can do with group settings:" +
        '<ul><li>Create group names and select colors./li>' +
        '<li>Define group permissions: Set permissions for managing community, members, channels, posts, gift talks, and more.</li></ul>' +
        'With unlimited group creation and detailed permission settings, you can easily manage your community and its members.',
    },
    {
      question: 'How far can community design customization go?',
      answer:
        'In CIETY, you can customize your community home by uploading cover images for both PC and mobile apps.\n\n' +
        'Currently, CIETY communities only support dark mode, which may result in somewhat limited design options.\n' +
        'However, we plan to enhance customization features to include community-specific images, beyond just light mode, in the future.',
    },
    {
      question:
        'Can notifications be customized differently for each community?',
      answer:
        'Yes! In CIETY, you can turn notifications on or off for each community individually,\n' +
        'as well as enable or disable notifications for channels within the community.\n\n' +
        'In the mobile app, you can further customize notification settings such as badge notifications and sounds in the app settings.',
    },
    {
      links: [
        { label: 'MarppleShop', url: 'https://marpple.shop/kr' },
        { label: 'MarppeShop Creator', url: 'https://marpple.shop/kr/@/hello' },
      ],
      question: 'I want to create and sell community goods!',
      answer:
        "CIETY supports an all-in-one commerce integration called MarppleShop, from opening a creator's store to simple payment,\n" +
        'goods production, shipping, and customer management.\n\n' +
        'After joining MarppleShop and going through the creator application process, once approved,n' +
        'you can open your store within days. You can easily create goods with your own designs and sell them immediately without inventory.\n' +
        'Try selling over 1,200 diverse goods in the CIETY community shop. By connecting your MarppleShop ID to the CIETY community,\n' +
        'MarppleShop products will be displayed at the top of the community channel list.\n\n' +
        'Besides goods reflecting the ideas and needs of community members, plan various monetization strategies.\n' +
        'You can sell concert tickets, brand advertisement/collaboration products, handmade items, and digital products\n' +
        '(digital wallpapers, mp3 files, etc.) without limitations on the types of goods.\n\n',
    },
    {
      question: 'Is live streaming possible in CIETY communities?',
      answer:
        'CIETY is currently preparing a community membership service. \n\n' +
        'You can create a live stream channel by selecting the live stream channel type and then customize its visibility and permissions.\n' +
        'You can choose to allow any member to live stream or restrict it to administrators only. Feel free to engage with\n' +
        'members through real-time gaming sessions, community-related lectures, or casual chats about daily activities via live streaming.\n\n' +
        'After the broadcast ends, the video and chat will be archived in the live stream channel. Additionally,\n' +
        'simultaneous streaming to YouTube Live is supported, making it easy to manage both your YouTube channel and community in one place.',
    },
    {
      question: 'Can CIETY create a paid community membership?',
      answer:
        'CIETY is currently preparing a community membership service. ' +
        '<ul><li>Discounts on community goods</li>' +
        '<li>Privilege to purchase a membership welcome package</li>' +
        '<li>Access to paid content or classes</li>' +
        '<li>Exclusive communication space for membership members</li>' +
        '<li>Access to direct messaging with creators</li></ul>' +
        'CIETY is committed to continuously striving to create a more free and high-quality\n' +
        'community platform for better community operation.',
    },
    {
      question: 'How do you create and sell paid content/classes?',
      answer:
        'In CIETY, you can easily create and sell content within the community.\n\n' +
        'By setting the visibility options in the Post Channel, Chat Channel, and Live Stream Channel settings to "Restricted," you can specify which groups can participate.\n' +
        'By selecting the paid membership group as the participating group, only members of that group can access and participate in the channel. \n' +
        'This allows you to monetize existing content or videos without using other class platforms. \n\n' +
        'Utilize the CIETY community to realize various creator economies.',
    },
    {
      question: 'Can community members be blocked or reported?',
      answer:
        'Any community member can report posts, comments, or chat messages if there are reasons for concern to the CIETY product team.\n' +
        'Additionally, community members can block/unblock other members they do not wish to see during community activities.\n\n' +
        'If you are a community administrator, you can also delete or restore community members within the community.',
    },
    {
      question:
        'What happens to community and activity records if I delete my CIETY account?',
      answer:
        'Even if you delete your CIETY account, data such as posts made during community activities will not be deleted.\n' +
        "If you're a community administrator, you can transfer your administrator privileges to another member before deleting your CIETY account.\n\n" +
        '<p style="color: #CD3849;">! If you delete your CIETY account, you won\'t be able to access the activity history or community membership lists associated with that account anymore.</p>',
    },
    {
      question: 'Can I also delete a community I created on CIETY?',
      answer:
        'To delete a community, please contact the CIETY product team at <a href="mailto:contact@ciety.io">contact@ciety.io</a>.\n\n' +
        '<p style="color: #CD3849;">! Please note that once you delete a CIETY community, all data left in the community cannot be recovered.</p>',
    },
  ],
};
