import { useEffect, useState } from 'react';
import { useMotionValueEvent, useTransform } from 'framer-motion';
import { useAtom } from 'jotai';
import { isMobileAtom, pageInitAtom } from '../logics/atom';

async function fetchImages(url) {
  return new Promise((res, rej) => {
    const img = document.createElement('img');
    img.addEventListener('load', res);
    img.addEventListener('error', rej);
    img.src = url;
  });
}

export function useSequences(images: Array<string> = []): [string[], boolean] {
  const [pageInit] = useAtom(pageInitAtom);
  const [loaded, setLoaded] = useState(false);
  const [sequence] = useState<Array<string>>(images);
  async function loadImages() {
    await Promise.all(images.map((url) => fetchImages(url)));
    setLoaded(true);
  }

  useEffect(() => {
    if (pageInit) loadImages();
  }, [pageInit]);

  return [sequence, loaded];
}

export function log(value) {
  useMotionValueEvent(value, 'change', (last) => {
    console.log(last);
  });
}

export function onChange(value, func: (lastValue) => void) {
  useMotionValueEvent(value, 'change', func);
}

export function useRange(prop, desktop: Array<Array<string | number>>, mobile) {
  const [isMobile] = useAtom(isMobileAtom);
  return useTransform(
    prop,
    !isMobile ? desktop[0] : mobile ? mobile[0] : desktop[0],
    !isMobile ? desktop[1] : mobile ? mobile[1] : desktop[1],
  );
}
