import classNames from 'classnames';
import styl from './styles.module.scss';
import Profile from './Profile';

interface ChatProps {
  thumb: string | string[];
  text: string | string[] | null;
  isMe?: boolean;
  isGroup?: boolean;
  isComma?: boolean;
  isCiety?: boolean;
  children?: any;
}

export default function Chat(props: ChatProps) {
  const { thumb, text, isMe, isGroup, isComma, isCiety, children } = props;

  return (
    <div
      className={classNames(styl.chat, {
        [styl.me]: isMe,
        [styl.ciety]: isCiety,
        [styl.comma]: isComma,
      })}
    >
      <div
        className={classNames(styl.thumbnail, {
          [styl.group]: isGroup,
        })}
      >
        {Array.isArray(thumb) ? (
          thumb.map((src) => <Profile key={src} src={src} />)
        ) : (
          <Profile src={thumb} />
        )}
      </div>

      {Array.isArray(text) ? (
        text.map((t, idx) => <p key={'text_' + idx}>{t}</p>)
      ) : (
        <p>{text}</p>
      )}

      {children}
    </div>
  );
}
