import MobileDetect from 'mobile-detect';
import { useScroll, useSpring } from 'framer-motion';

export const classes = (...clx: any[]) => clx.join(' ');

export const setDuration = (sec: number) => 1000 * sec;

export const randomNum = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const makeUrl = (path: string) => {
  const { host, protocol } = location;
  const isProd = protocol === 'https:';
  return !isProd
    ? `${path}`
    : `${protocol}//${host.split('.').slice(-2).join('.')}${path}`;
};

export const getUA = () => {
  return new MobileDetect(window.navigator.userAgent);
};

export const spring = (val: any) => {
  if (getUA().mobile()) {
    return useSpring(val);
  } else {
    return val;
  }
};

export function responsive(xl, lg, md) {
  return window.innerWidth > 1920 ? xl : window.innerWidth > 1440 ? lg : md;
}

export function makeScroll(offset) {
  return (ref) =>
    useScroll({
      layoutEffect: false,
      target: ref,
      offset: offset,
    });
}

export function comma(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const setValues = () => {
  setValue('--screen-width-window', window.innerWidth);
  setValue('--screen-height-window', window.innerHeight + 'px');

  if (window.innerWidth < 768) {
    setValue('--screen-width', Math.max(window.innerWidth, 768));
    if (window.innerHeight < 640) {
      setValue('--screen-height', window.innerHeight);
      setValue('--screen-height-px', window.innerHeight + 'px');
    } else {
      setValue('--screen-height', Math.max(window.innerHeight, 850));
      setValue('--screen-height-px', Math.max(window.innerHeight, 850) + 'px');
    }
  } else {
    setValue('--screen-width', Math.max(window.innerWidth, 1440));
    setValue('--screen-height', Math.max(window.innerHeight, 850));
    setValue('--screen-height-px', Math.max(window.innerHeight, 850) + 'px');
  }
};

export const setValue = (key, value) => {
  document.documentElement.style.setProperty(key, value);
};
