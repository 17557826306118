import styl from './styles.module.scss';

export default function Cut({
  duration,
  children,
  className = '',
  bg = 'transparent',
}) {
  return (
    <div
      style={{ height: duration, background: bg }}
      className={`${styl.cut} ${className}`}
    >
      <div className={styl.viewport}>{children}</div>
    </div>
  );
}
