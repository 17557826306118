import { motion, useTransform } from 'framer-motion';
import { useAtom } from 'jotai';
import styl from '../Web3/styles.module.scss';
import { isMobileAtom, screenHeightAtom } from '../../logics/atom';
import { useEffect, useRef } from 'react';
import { makeScroll } from '../../utils/func';
import Merch from '@Components/module/Merch';
import { useTranslation } from 'react-i18next';

export default function Layer4({ changeHeaderColor }) {
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);
  const [screenHeight] = useAtom(screenHeightAtom);
  const storeRef = useRef(null);
  const storeScroll = makeScroll(['start end', 'end end']);
  const { scrollYProgress: storeProgress } = storeScroll(storeRef);

  const backgroundColor = useTransform(
    storeProgress,
    [0.7, 1],
    ['#1AAEFC', '#1AAEFC'],
  );

  useEffect(() => {
    const unsub1 = storeProgress.on('change', (latest) => {
      if (latest > 0.9) changeHeaderColor('green');
      else changeHeaderColor('black');
    });
    return () => {
      unsub1();
    };
  }, []);

  return (
    <motion.div className={styl.layer4} style={{ backgroundColor }}>
      <div ref={storeRef} style={{ height: screenHeight * 3 }}>
        <Merch containerRef={storeRef} />
      </div>
    </motion.div>
  );
}
