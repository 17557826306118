const category = {
  All: 'All',
  etc: 'etc',
  Game: 'Game',
  Photography: 'Photography',
  Lifestyle: 'Lifestyle',
  Movie: 'Movie',
  Economy: 'Economy',
  Education: 'Education',
  Kpop: 'Kpop',
  Politic: 'Politic',
  Science: 'Science',
  Food: 'Food',
  Pet: 'Pet',
  Music: 'Music',
  'Health / Sports': 'Health / Sports',
  IT: 'IT',
  'Creative Writing': 'Creative Writing',
  Travel: 'Travel',
  'Art / Design': 'Art / Design',
  'Faith / Religion': 'Faith / Religion',
  'Fashion / Beauty': 'Fashion / Beauty',
};

export default {
  category,
};
