const IMAGE_BASE_PATH_PC = 'web3/pc/';
const IMAGE_BASE_PATH_MO = 'web3/mobile/';

const section1 = {
  heading: '사이어티에서 당신만의\nWeb3.0 세계를 만들어 보세요',
  headingM: '사이어티에서 당신만의\n' + 'Web3.0 세계를 만들어 보세요',
  desc: 'Community Platform for Creators\nYour World, Your CIETY',
  buttonLabel: '커뮤니티 만들기',
  buttonLabelM: '커뮤니티 시작하기',
};

const section2 = {
  text3: '이 세상엔\n수많은 관심사들이 있어요!',
  text3M: '이 세상엔 수많은\n' + '관심사들이 있어요!',
  chat1: '저와 관심사가 비슷한 친구들을\n내 커뮤니티에 모을 수 있을까요?',
  chat2: '그럼요~ 얼마든지요!\n관심사가 어떻게 되세요?',
  chat3:
    '저는 게임을 정말 좋아해요!\n그래서 저는 저만의 게임 월드를\n만들고 싶어요!',
  chat4: '만들어 보세요!\n나만의 사이어티!',
  textImage1: `${IMAGE_BASE_PATH_PC}Section2-text1.png`,
  textImage2: `${IMAGE_BASE_PATH_PC}Section2-text1.png`,
  textImage1M: `${IMAGE_BASE_PATH_PC}Section2-text1.png`,
  textImage2M: `${IMAGE_BASE_PATH_PC}Section2-text1.png`,
};

const section3 = {
  title: '재미난 관심사와 이야기로 가득한\n' + '새로운 세상!',
  titleM: '재미난 관심사와 이야기로\n' + '가득한 새로운 세상!',
  desc:
    '커뮤니티 멤버들과 자유롭게 대화할 주제를 올리고\n' +
    '채팅창에서 자유롭게 이야기를 나눠보세요!\n' +
    '이모지로 자유로운 소통도 가능해요!',
  image: `${IMAGE_BASE_PATH_PC}Section3-img.png`,
  messages: [
    '이벤트 오늘까지니까 모두들 많관부!',
    '퀘스트 쉽게 깨는 법 알려드림~',
    '저는 마녀 종족인데, 저도 껴주세요!',
    '요새 게임 스트리머 누구 봄?',
    '내가 올린 마법사 팬 아트 어때??',
  ],
  messagesM: [
    '이벤트 오늘까지니까\n' + '모두들 많관부!',
    '퀘스트 쉽게 깨는 법\n' + '알려드림~',
    '저는 마녀 종족인데,\n' + '저도 껴주세요!',
    '요새 게임 스트리머\n' + '누구 봄?',
    '내가 올린 마법사\n' + '팬 아트 어때??',
  ],
};

const section4 = {
  title: '기프톡으로 커뮤니티 멤버들에게\n' + '마음을 전달해 보세요',
  desc:
    '사이어티에서는 GIFTOK를 통해 선물처럼 토큰을 주고 받을 수 있어요!\n' +
    '커뮤니티 멤버나 좋아하는 크리에이터에게 100% 마음을 전하세요!\n' +
    '비디오 스트리밍을 시청하면서 좋아하는 크리에이터나 멤버들에게 기프톡을 해보세요!',
  descM:
    '사이어티에서는 GIFTOK를 통해\n' +
    '선물처럼 토큰을 주고 받을 수 있어요!\n' +
    '비디오 스트리밍을 시청하면서 좋아하는\n' +
    '크리에이터나 멤버들에게 기프톡을 해보세요!',
};

const section5 = {
  title:
    '당신만의 익스클러시브한 커뮤니티를 만들고\n' + '멤버들을 초대해 보세요!',
  titleM:
    '당신만의 익스클러시브한\n' +
    '커뮤니티를 만들고\n' +
    '멤버들을 초대해 보세요!',
  desc:
    '토큰 게이팅을 활용해 특정 토큰을 보유한 멤버들만 커뮤니티에 초대할 수 있어요!\n' +
    '커뮤니티가 특정 멤버들에 대해 익스클러시브한 공간을 마련하고,\n' +
    '멤버들과 함께 특별한 가치를 만들어나갈 수 있습니다.',
  descM:
    '토큰 게이팅을 활용해 특정 토큰을\n' +
    '보유한 멤버들만 커뮤니티에 초대할 수 있어요!\n' +
    '멤버들과 특별한 가치를 만들어나갈 수 있습니다.',
  messages: [
    '우리만을 위한 특별한 커뮤니티를 만들었어!',
    '입장할 수 있는 NFT 초대장 카드야!',
    '오~~~~~뭐야!!!\n' + '고마워!!!',
    'NFT 초대장 카드를 가진 멤버들만 \n' +
      '커뮤니티에 가입이 가능해요! \n' +
      '지갑에 잘 간직해주세요!\n' +
      '우리 모두 커뮤니티에서 만나요!',
  ],
  messagesM: [
    '우리를 위한 특별한 \n' + '커뮤니티를 만들었어!',
    '입장할 수 있는 NFT 초대장 카드야!',
    '오~~~~~뭐야!!!\n' + '고마워!!!',
    'NFT 초대장 카드를 가진 멤버들만 커뮤니티에 가입이 가능해요! ',
  ],
  cardTitle: 'Crazy Game World',
  cardDesc:
    '커뮤니티에 참여하기 위해서는\n' +
    '아래의 디지털 초대장 1장을 소유해야 합니다',
  cardButtonLabel: '커뮤니티 가입하기',
};

const section6 = {
  startText: '3명의 멤버가 커뮤니티에 입장했습니다',
  title: '당신만의 커뮤니티 스토어를\n' + '만들수도 있어요!',
  desc:
    '사이어티에서는 커뮤니티 자체가 브랜드가 될 수 있어요!\n' +
    'Marketplace를 만들어서 커뮤니티만의 NFT를 판매하거나 구매할 수 있습니다.\n' +
    '커뮤니티 샵을 통해 굿즈 상품 거래도 모두 가능해요!',
  descM:
    'Marketplace에서 커뮤니티의 \n' +
    'NFT를 판매하거나 구매할 수 있습니다. \n' +
    '커뮤니티 샵을 통해 굿즈 상품 거래도 모두 가능해요!',
  chat1: '커뮤니티 플랫폼,\n사이어티에서 시작하세요!',
  chat2: '나만의 브랜드를\n만들어 보세요!',
  chat3: '커뮤니티 멤버들과 함께\n다양한 굿즈를 만들 수 있어요!',
};

const section7 = {
  title: '크리에이터 이코노미를 위한\n' + '새로운 세상, 사이어티',
  desc:
    '사이어티는 내가 꿈꾸던 커뮤니티를 보다 쉽게 만들 수 있도록 도와드립니다.\n' +
    '사이어티를 통해 크리에이터를 열정을 특별한 수익으로 만들 수도 있고,\n' +
    '커뮤니티 멤버들과 함께 수익을 창출할 수도 있습니다.',
  descM:
    '사이어티를 통해 크리에이터를 열정을\n' +
    '특별한 수익으로 만들 수 있고,\n' +
    '멤버들과 함께 수익을 창출할 수도 있어요.',
};
const section8 = {
  title: '사이어티는 커뮤니티의\n' + '성장에 대해 늘 고민합니다',
  feat1:
    '사이어티는 커뮤니티 리더와 멤버 간 연결을 \n' +
    '촉진할 수 있고 커뮤니티를 강력하게 성장할 수 있도록 \n' +
    '종합적인 도구와 자원을 지속 제공합니다.',
  feat2:
    '사이어티에서는 커뮤니티 멤버들과\n' +
    'MarketPlace를 개설해 디지털 자산을 선물하거나\n' +
    '판매할 수 있습니다.',
  feat3:
    '커뮤니티 리더는 멤버들과 함께 커뮤니티 운영으로\n' +
    '개별 활동에 기반한 경제적 보상을 추구하는 등\n' +
    '창의적으로 전개할 수 있습니다.',
};

const section9 = {
  title: '다양한 관심사를 위한\n' + '사이어티 커뮤니티',
  desc:
    '사이어티는 소그룹, 취미 커뮤니티, 전문기관 모두를 위한 플랫폼입니다.\n' +
    '사이어티는 모든 커뮤니티가 비즈니스적으로 잠재력을 가지고 있다고 믿으며,\n' +
    '언제나 커뮤니티가 성장할 수 있도록 도움을 드릴 준비가 되어 있습니다.',
  descM:
    '사이어티는 소그룹, 취미 커뮤니티, 전문기관\n' +
    '모두를 위한 플랫폼입니다.\n' +
    '언제나 커뮤니티가 성장할 수 있도록\n' +
    '도움을 드릴 준비가 되어 있습니다.',
};

const section10 = {
  title: '사이어티에서 당신만의\n' + 'web3.0 세계를 만들어 보세요',
  desc:
    '당신은 어떤 것에 관심이 있고, 그 관심사를 누구와 연결하고 싶으신가요?\n' +
    '사이어티는 당신이 상상하는 모든 것을 만들 수 있는 곳입니다.\n' +
    '새로운 시대의 크리에이터가 되어 보세요!',
  descM:
    '당신은 어떤 것에 관심이 있고,\n' +
    '그 관심사를 누구와 연결하고 싶으신가요?\n' +
    '사이어티는 당신이 상상하는 모든 것을 만들 수 있어요.\n' +
    '새로운 시대의 크리에이터가 되어 보세요!',
  buttonLabel: '커뮤니티 시작하기',
};

export default {
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
  section10,
};
