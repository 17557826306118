export default {
  title: '사이어티에 대해\n' + '더 자세히 알려드릴게요!',
  questions: [
    {
      question: '커뮤니티를 만들고 싶어요! 1:1 상담을 받을 수 있나요?',
      answer:
        '커뮤니티를 만들고 싶은 데, 어떤 플랫폼을 선택해야 할지 고민하고 계시다면,\n' +
        '이름, 연락처, 소셜 링크와 궁금한 문의사항을 적어 <a href="mailto:contact@ciety.io">contact@ciety.io</a> 으로 보내주세요.\n\n' +
        '사이어티 프로덕트 팀은 언제나 커뮤니티 개설을 도울 준비가 되어 있습니다.',
    },
    {
      question: '유튜브 크리에이터가 아니라도 커뮤니티를 만들 수 있나요?',
      answer:
        '네! 사이어티에서는 누구나 쉽게 커뮤니티를 개설할 수 있습니다.\n\n' +
        '학교 친구들과 스터디 과제를 공유하거나, 좋아하는 게임 길드를 만들어 라이브 스트리밍으로 함께 게임하거나,\n' +
        '취미 동아리 커뮤니티를 개설해 이벤트를 만드는 등 관심 있는 주제로 커뮤니티를 만들어 자유롭게 소통해 보세요.\n\n' +
        '유튜브 채널이 아니라도, 인스타그램이나 X(구 트위터), 틱톡, 페이스북 등에서 크리에이터로 활동하고 계신다면\n' +
        '사이어티에서 커뮤니티를 만들어 보세요. 글과 사진, 영상, 라이브 스트림, 굿즈 판매, 유료 클래스 판매까지 \n' +
        '원하는 모든 걸 사이어티에서 나눠 보세요.',
    },
    {
      question: '사이어티는 왜 유튜브 크리에이터를 위한 커뮤니티 플랫폼인가요?',
      answer:
        '사이어티는 유튜브 채널을 연동한 유튜브 채널 공식 커뮤니티를 만들 수 있습니다.\n' +
        '유튜브 채널을 사이어티와 연동한 커뮤니티는 아래와 같은 기능을 활용할 수 있습니다.\n' +
        '<ol><li>유튜브 채널 구독자를 확인해 구독자 계정만 커뮤니티에 가입할 수 있도록 커뮤니티 가입 조건을 설정할 수 있습니다.</li>' +
        '<li>채널 구독자와 멤버십 단계에 따라 멤버 그룹을 만들어 커뮤니티 내 포스팅· 채팅· 라이브 스트림 채널마다 특정 그룹만 권한을 설정할 수 있습니다.</li>' +
        '<li>유튜브 채널에 영상을 업로드하면 사이어티 커뮤니티에도 자동으로 연동 및 공유되어 영상 콘텐츠를 커뮤니티 멤버들에게 한 번 더 알리고 아카이브할 수 있습니다.</li>' +
        '<li>유튜브 쇼핑을 연동해 유튜브 채널에서 판매하고 있는 마플샵 상품을 사이어티 커뮤니티에서도 동일하게 노출하여 판매할 수 있습니다.</li></ol>' +
        '이 외에도 사이어티는 다양한 크리에이터 분들을 위한 커뮤니티 플랫폼을 만들고자 지속적인 기능 고도화를 이뤄내고 있습니다.\n' +
        '사이어티 커뮤니티 이용 시, 불편 사항이나 개선에 대한 의견이 있으시다면 언제든  <a href="mailto:contact@ciety.io">contact@ciety.io</a>  으로 문의 부탁드립니다.',
    },
    {
      question: '사이어티 계정 하나로 커뮤니티를 여러개 생성할 수 있나요?',
      answer:
        '물론이죠. 사이어티에서는 계정 하나로 다수의 커뮤니티를 개설할 수 있습니다.' +
        '<ul><li>취미 동아리, 업무 스터디, 학교 과제 모임, 지역 커뮤니티, 팬카페 등의 다양한 커뮤니티를 만들어 보세요.</li>' +
        '<li>커뮤니티에서 글과 사진, 영상을 남기는 포스팅 채널, 채팅 채널, 라이브 스트림 채널을 생성해 내 커뮤니티를 더욱 풍부하게 만들어 보세요.</li>' +
        '<li>커뮤니티에서 그룹 별로 권한을 다르게 설정하여 내 커뮤니티를 보다 섬세하게 관리해 보세요.</li></ul>' +
        '사이어티는 무제한 커뮤니티 개설, 무제한 채널 생성, 무제한 멤버 그룹 생성으로 자유도 높은 커뮤니티 플랫폼을 경험할 수 있습니다.',
    },
    {
      question: '커뮤니티 채널 설정에 대해 자세히 알고 싶어요!',
      answer:
        '사이어티 커뮤니티에서 채널이란, 포스트나 채팅, 라이브 스트림을 업로드하는 게시판과 같은 의미를 가집니다.\n' +
        '사이어티 커뮤니티의 채널 설정에서는 아래와 같은 기능을 설정할 수 있습니다.' +
        '<ul><li>채널 타입 선택, 채널 명, 채널 설명 작성</li>' +
        '<li>채널 공개 설정 : 공개 / 제한 / 비공개</li>' +
        '<li>채널 권한 설정 : 선택한 멤버 그룹의 해당 채널 권한 설정</li></ul>' +
        '사이어티 커뮤니티는 포스트 에디터를 갖춘 포스트 채널· 채팅 채널· 라이브 스트림 채널 세 가지 타입이 있습니다.\n' +
        '채널은 무제한 생성 가능하기 때문에 다양한 이야기를 나누는 자유로운 커뮤니티를 만들어 관리해 보세요.',
    },
    {
      question: '커뮤니티 그룹 설정에 대해 자세히 알고 싶어요!',
      answer:
        '사이어티 커뮤니티에서는 다양한 멤버 그룹을 무제한 만들어, 섬세하게 권한을 부여할 수 있습니다.\n' +
        '커뮤니티 내 모든 멤버들은 그룹이 없거나 혹은 다수의 그룹에 속할 수 있습니다.' +
        '<ul><li>그룹 이름 작성, 색상 선택/li>' +
        '<li>그룹 권한 설정 : 커뮤니티, 멤버, 채널, 게시물, 기프톡 관리 등의 권한 설정</li></ul>' +
        '무제한 그룹 생성과 섬세한 권한 설정으로 커뮤니티와 멤버들을 쉽게 관리해 보세요.',
    },
    {
      question: '커뮤니티 디자인 커스텀은 어디까지 가능한가요?',
      answer:
        '사이어티에서는 PC와 모바일 앱의 커버 이미지를 등록해 커뮤니티 홈을 커스텀 할 수 있습니다.\n\n' +
        '현재 사이어티 커뮤니티는 다크 모드만 지원하고 있기 때문에 다소 제한적인 디자인 일 수 있지만,\n' +
        '추후 라이트 모드 외에도 커뮤니티만의 이미지를 보여줄 수 있는 디자인 커스텀을 위해 기능 고도화를 계획하고 있습니다.',
    },
    {
      question: '커뮤니티마다 알림 설정을 다르게 할 수 있나요?',
      answer:
        '네! 사이어티는 커뮤니티마다 알림 설정을 끄거나 켤 수 있고,\n' +
        '커뮤니티 내 채널마다 알림을 ON/OFF 할 수도 있습니다.\n\n' +
        '모바일 앱에서는 앱 설정에서 배지 알림, 사운드 등의 알림 설정이 가능합니다.',
    },
    {
      links: [
        { label: '마플샵 홈페이지', url: 'https://marpple.shop/kr' },
        {
          label: '마플샵 크리에이터 신청',
          url: 'https://marpple.shop/kr/@/hello',
        },
      ],
      question: '커뮤니티 굿즈를 만들어 판매하고 싶어요!',
      answer:
        '사이어티에서는 크리에이터의 스토어 개설부터 간편 결제, 굿즈 제작, 배송, 고객 관리까지 올인원 커머스 마플샵 연동을 지원합니다.\n\n' +
        '마플샵 가입 후, 크리에이터 신청 절차를 거쳐 승인을 받으면 단 며칠 만에 스토어를 오픈하고\n' +
        '나만의 디자인으로 쉽게 굿즈를 만들어 재고 없이 바로 판매할 수 있어요.\n' +
        '1,200여 종의 다양한 굿즈를 만들어 사이어티 커뮤니티에서 판매해 보세요.\n\n' +
        '사이어티 커뮤니티 샵에 마플샵 아이디를 연결하면, 커뮤니티 채널 리스트 상단에 마플샵 상품이 노출됩니다.\n' +
        '커뮤니티 멤버의 아이디어와 니즈를 반영한 굿즈 상품 외에도 다양한 수익화를 기획해 보세요.\n' +
        '콘서트 티켓, 브랜드 광고/콜라보 상품, 핸드메이드 상품, 디지털 상품(핸드폰 배경화면, mp3 음원 등) 등 상품 유형의 제약 없이 판매할 수 있습니다.\n\n',
    },
    {
      question: '사이어티 커뮤니티에서 라이브 스트리밍도 가능한가요?',
      answer:
        '사이어티에서는 커뮤니티에서 라이브 스트리밍을 할 수 있습니다.\n\n' +
        '라이브 스트림 채널 타입을 선택해 채널을 만들고 공개 설정과 권한 설정으로\n' +
        '라이브 스트리밍을 멤버 누구나 할 수 있거나, 관리자만 업로드할 수 있는 채널로 관리할 수 있어요.\n' +
        '멤버들과 실시간 게임을 함께 하고 커뮤니티 주제 관련 강의를 하고 매일 하루 일과를 나누는 가벼운 소통을 실시간 라이브 스트리밍으로 나눠 보세요.\n\n' +
        '방송 종료 이후 영상과 채팅이 라이브 스트림 채널에 그대로 기록되며,\n' +
        '유튜브 라이브와 동시 송출도 가능해서 내 유튜브 채널과 커뮤니티를 한 번에 쉽게 관리할 수 있습니다.',
    },
    {
      question: '커뮤니티 유료 멤버십을 만들 수 있나요?',
      answer:
        '사이어티에서는 커뮤니티 멤버십 서비스를 준비 중입니다.' +
        '<ul><li>커뮤니티 굿즈 할인 혜택</li>' +
        '<li>멤버십 웰컴 패키지를 구매할 수 있는 권한</li>' +
        '<li>유료 콘텐츠 혹은 유료 클래스를 볼 수 있는 권한</li>' +
        '<li>멤버십 멤버끼리만 소통할 수 있는 공간</li>' +
        '<li>크리에이터와 다이렉트 메시지 이용권</li></ul>' +
        '위와 같은, 멤버십이 줄 수 있는 다양한 혜택과 기능을 기획하고 있습니다.\n' +
        '사이어티는 보다 자유롭고 퀄리티 높은 커뮤니티 운영을 위한 커뮤니티 플랫폼을 만들어가기 위해 끊임없이 노력하겠습니다.',
    },
    {
      question: '유료 콘텐츠/클래스는 어떻게 만들어 판매하나요?',
      answer:
        '사이어티에서는 커뮤니티에서 콘텐츠를 만들어 쉽게 판매해 볼 수 있습니다.\n\n' +
        '포스트 채널, 챗 채널, 라이브 스트림 채널 설정에서 [공개 범위 설정]에서 제한으로 선택하고 참여 가능한 그룹을 설정할 수 있습니다.\n' +
        '유료 멤버십 그룹을 참여 그룹으로 선택하면, 멤버십 그룹만 해당 채널을 열람하고 참여할 수 있기 때문에\n' +
        '다른 클래스 플랫폼을 이용하지 않고도 기존의 콘텐츠나 영상을 모아 유료화해서 판매할 수 있습니다.\n\n' +
        '사이어티 커뮤니티를 활용해 다양한 크리에이터 이코노미를 실현해 보세요.',
    },
    {
      question: '커뮤니티 멤버를 차단하거나 신고 할 수 있나요?',
      answer:
        '커뮤니티 멤버라면 누구든지, 포스트나 댓글, 챗의 내용에 신고 사유가 있을 시, 사이어티 프로덕트 팀에 신고할 수 있습니다.\n' +
        '또한 커뮤니티 활동 중 보고 싶지 않은 멤버를 차단/차단 해제할 수 있습니다.\n\n' +
        '커뮤니티 관리자라면 커뮤니티 멤버를 커뮤니티 내에서 삭제하거나 다시 되돌릴 수도 있습니다.',
    },
    {
      question:
        '사이어티 계정을 삭제하면 커뮤니티와 활동했던 기록은 어떻게 되나요?',
      answer:
        '사이어티 계정을 삭제하더라도 커뮤니티 활동 중 작성한 포스트 등의 데이터는 삭제되지 않습니다.\n' +
        '커뮤니티 관리자라면, 커뮤니티의 관리자 권한을 다른 멤버에게 이전 후, 사이어티 계정을 삭제할 수 있습니다.\n\n' +
        '<p style="color: #CD3849;">! 사이어티 계정을 삭제하면, 해당 계정의 활동 내역이나 커뮤니티 가입 리스트 등에는 더 이상 접근할 수 없습니다.</p>',
    },
    {
      question: '사이어티 커뮤니티를 삭제할 수 있나요?',
      answer:
        '커뮤니티를 삭제하시려면 사이어티 프로덕트 팀 <a href="mailto:contact@ciety.io">contact@ciety.io</a>으로 문의해 주시기 바랍니다.\n\n' +
        '<p style="color: #CD3849;">! 사이어티 커뮤니티를 삭제하면, 커뮤니티에 남겨진 모든 데이터는 다시 복구할 수 없습니다.</p>',
    },
  ],
};
