import styl from './styles.module.scss';
import classNames from 'classnames';
import i18n from 'i18next';

interface SectionHeaderProps {
  icon?: string;
  white?: boolean;
  title: string;
  desc: string;
  align?: 'left' | 'center';
}
export default function SectionHeader({
  icon,
  white,
  title,
  desc,
  align = 'center',
}: SectionHeaderProps) {
  return (
    <div
      className={classNames(styl.sectionHeader, {
        [styl.white]: white,
        [styl.left]: align === 'left',
      })}
    >
      {icon && <img src={icon} className={styl.icon} />}
      <h3 className={styl[i18n.language]}>{title}</h3>
      <p>{desc}</p>
    </div>
  );
}
