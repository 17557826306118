const IMAGE_BASE_PATH_PC = 'web3/pc/';
const IMAGE_BASE_PATH_MO = 'web3/mobile/';

const section1 = {
  heading: 'Create your own\n' + 'world at CIETY',
  headingM: 'Create your own\n' + 'Web3.0 world at CIETY',
  desc: 'Community Platform for Creators\nYour World, Your CIETY',
  buttonLabel: 'Create CIETY',
  buttonLabelM: 'Create CIETY',
};

const section2 = {
  text3: 'There are so\n' + 'many diverse interests\n' + 'in the world',
  text3M: 'There are so\n' + 'many diverse interests\n' + 'in the world',
  chat1: 'Can I gather like-minded\n' + 'friends in my community?',
  chat2: 'Of course, you can!\n' + 'Choose your interest!',
  chat3:
    'I really like playing games!\n' +
    'so I really want to create\n' +
    'my own game world!',
  chat4: 'Create your own CIETY!',
  textImage1: `${IMAGE_BASE_PATH_PC}EN-Section2-text1.png`,
  textImage2: `${IMAGE_BASE_PATH_PC}EN-Section2-text2.png`,
  textImage1M: `${IMAGE_BASE_PATH_MO}EN-Section2-text1.png`,
  textImage2M: `${IMAGE_BASE_PATH_MO}EN-Section2-text2.png`,
};

const section3 = {
  title: 'A new world\n' + 'full of interesting\n' + 'topics and fun talks',
  titleM: 'A new world\n' + 'full of interesting\n' + 'topics and fun talks',
  desc:
    'Post a topic you want to talk about and\n' +
    'chat freely in the chat window.\n' +
    'You can also express your emotions simply with emojis.',
  image: `${IMAGE_BASE_PATH_PC}EN-Section3-img.png`,
  messages: [
    'The event ends today,\n' + 'so hurry everyone!',
    "I'll teach you how to\n" + 'easily complete quests~',
    "I'm a witch character,\n" + 'so please include me too!',
    "Who's watching game\n" + 'streamers these days?',
    'What do you think of the\n' + 'wizard fan art I posted??',
  ],
  messagesM: [
    'The event ends today,\n' + 'so hurry everyone!',
    "I'll teach you how to\n" + 'easily complete quests~',
    "I'm a witch character,\n" + 'so please include me too!',
    "Who's watching game\n" + 'streamers these days?',
    'What do you think of the\n' + 'wizard fan art I posted??',
  ],
};

const section4 = {
  title: 'This is my heart for you!',
  desc:
    'With GIFTOK, you can give and take your tokens like a gift.\n' +
    'Express your 100% heart to your community members or favorite creators!\n' +
    'Interact with your favorite creators or members while watching seamless video streaming.',
  descM:
    'With GIFTOK, you can give and take your tokens\n like a gift. Express your 100% heart to your\n community members or favorite creators! \nInteract with your favorite creators or members while watching seamless video streaming.',
};

const section5 = {
  title: 'Invite you to \nour exclusive space!',
  titleM: 'Invite you to  \nour exclusive space!',
  desc: 'Use the Token Gating to provide exclusive space for \nspecific token holders, enabling the community to create \na sense of exclusivity and value for its members.',
  descM:
    'Use the Token Gating to provide exclusive\n' +
    'space for specific token holders,\n' +
    'enabling the community to create a sense of\n exclusivity and value for its members.',
  messages: [
    'Invite you to our special world\n' + 'just for us!',
    'This is digital invitaion card for you!',
    'WOWWWWWWWW\n' + 'Thank you!!!!',
    'If you own this card,\n' + 'you can enter our\n' + 'exclusive commuity!!!',
  ],
  messagesM: [
    'Invite you to our \n' + 'special world just for us!',
    'Digital invitaion card for you!',
    'WOWWW\n' + 'Thank you!!!!',
    'If you own this card,\n' + 'you can enter our\n' + 'exclusive commuity!',
  ],
  cardTitle: 'Crazy Game World',
  cardDesc:
    'If you want to join our exclusive community,\n' +
    'you must own 1 digital invitaion card below.',
  cardButtonLabel: 'Join Now',
};

const section6 = {
  startText: '3 Members have entered this community now',
  title: 'Create your own\n' + 'community’s store',
  desc: 'The community itself becomes the brand on CIETY.\nYou can create your community marketplace. Sell and buy community branded swag!\nBoth digital and physical goods are available on CIETY marketplace.',
  descM:
    'The community itself becomes the brand on CIETY. You can create your community marketplace. Sell and buy community branded swag! Both digital and physical goods are available on CIETY marketplace.',
  chat1: 'You can be a creator\n' + 'of a new world!',
  chat2: 'You can make\n your own brand!',
  chat3: 'You can also make\nvarious merch with your\ncommunity members!',
};

const section7 = {
  title: 'A new world for\n' + 'creator economy',
  desc: 'Welcome to CIETY, a world of endless possibilities for the creator economy!\nIn this era, community creators are akin to rockstars, and CIETY makes\nbuilding the community of your dreams easier than ever before. With our\nplatform, you can transform your passion into profit and earn alongside\nfellow community members.',
  descM:
    'Welcome to CIETY, a world of endless possibilities for the creator economy!\nIn this era, community creators are akin to rockstars, and CIETY makes building the community of your dreams easier than ever before. With our platform, you can transform your passion into profit and earn alongside\nfellow community members.',
};
const section8 = {
  title: 'We always think about\n' + 'community growth',
  feat1:
    'CIETY offers a comprehensive suite of tools and resources designed to facilitate member engagement, foster meaningful connections, and cultivate a supportive environment for the growth of strong, interconnected communities.',
  feat2:
    "Whether you're opening a marketplace with your community members or gifting digital assets, CIETY provides an array of limitless features to facilitate the growth of your community. so you can focus on the creative aspects of community operations with your members.",
  feat3:
    "CIETY's diverse range of monetization features enable both community creators and members to reap economic rewards based on their individual activity.",
};

const section9 = {
  title: 'One CIETY for\n' + 'all communities',
  desc:
    "Whether you're a group of friends, a hobbyist community,\n" +
    'or a professional organization, CIETY is for you.\n' +
    'We believe that every community has the potential to be a successful business,\n' +
    "and we're here to help you achieve that.",
  descM:
    "Whether you're a group of friends, a hobbyist community, or a professional organization, CIETY is for you. We believe that every community has the potential to be a successful business, and we're here to help you achieve that.",
};

const section10 = {
  title: 'CIETY coexist\n' + 'with communities',
  desc:
    'CIETY believes in sharing our success with our community creators.\n' +
    'We are committed to sharing our revenue with active communities on CIETY and growing together.\n' +
    'Are you passionate about something and want to connect with others who share your interests?\n' +
    'Come build your own community on CIETY today, and let your dreams take flight!',
  descM:
    'CIETY believes in sharing our success with our community creators. We are committed to sharing our revenue with active communities on CIETY and growing together. Are you passionate about something and want to connect with others who share your interests?\n' +
    'Come build your own community on CIETY today, and let your dreams take flight!',
  buttonLabel: 'Create CIETY',
};

export default {
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
  section10,
};
