import { default as creator } from './creator';
import { default as web3 } from './web3';
import { default as home } from './home';
import { default as faq } from './faq';
import { default as world } from './world';

const nav = {
  home: 'Home',
  creator: 'for Creator',
  web3: 'for Web3',
  world: 'Discover World',
  faq: 'FAQ',
  signin: 'Sign in',
};

export const translation = {
  nav,
  creator,
  web3,
  home,
  faq,
  world,
};
