import { useEffect, useRef, useState } from 'react';
import {
  useMotionValue,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';

export const useDesktopHelper = () => {
  const layer1Ref = useRef(null);
  const layer2Ref = useRef(null);
  const layer3Ref = useRef(null);
  const layer3MaskRef = useRef(null);
  const layer4Ref = useRef(null);

  const isMobile = window.innerWidth < 768;

  const visualWidth = useMotionValue<number>(1440);
  const visualHeight = useMotionValue<number>(800);
  const visualRadius = useMotionValue<number>(200);
  const visualRadiusFinal = useMotionValue<number>(60);

  const { scrollYProgress: layer1ScrollY } = useScroll({
    layoutEffect: false,
    target: layer1Ref,
    offset: ['start end', 'end end'],
  });
  const { scrollYProgress: layer2ScrollY } = useScroll({
    layoutEffect: false,
    target: layer2Ref,
    offset: ['center end', 'center center'],
  });
  const { scrollYProgress: layer3ScrollY } = useScroll({
    layoutEffect: false,
    target: layer3Ref,
    offset: ['start end', 'end end'],
  });
  const { scrollYProgress: layer3MaskScrollY } = useScroll({
    layoutEffect: false,
    target: layer3MaskRef,
    offset: ['start start', 'end center'],
  });
  const { scrollYProgress: layer4ScrollY } = useScroll({
    layoutEffect: false,
    target: layer4Ref,
    offset: ['start end', 'end end'],
  });

  const [headerColor, setHeaderColor] = useState('green');
  const [bgColor, setBGColor] = useState('');
  const [layer1SP, setLayer1SP] = useState(0);
  const [layer2SP, setLayer2SP] = useState(0);
  const [layer3SP, setLayer3SP] = useState(0);
  const [layer3MSP, setLayer3MSP] = useState(0);
  const [layer4SP, setLayer4SP] = useState(0);

  useMotionValueEvent(layer1ScrollY, 'change', (last) => {
    if (last > 0.8) {
      setHeaderColor('green');
    } else {
      setHeaderColor('black');
    }
    setLayer1SP(parseFloat(last.toFixed(2)));
  });
  useMotionValueEvent(layer2ScrollY, 'change', (last) => {
    if (last > 0.45) {
      setBGColor('brown');
    } else {
      setBGColor('');
    }
    setLayer2SP(parseFloat(last.toFixed(2)));
  });
  useMotionValueEvent(layer3ScrollY, 'change', (last) => {
    if (last > 0.3) {
      setBGColor('purple');
    } else {
      setBGColor('brown');
    }
    setLayer3SP(parseFloat(last.toFixed(2)));
  });
  useMotionValueEvent(layer3MaskScrollY, 'change', (last) => {
    if (last > 0.02) {
      setBGColor('black');
    } else {
      setBGColor('purple');
    }
    setLayer3MSP(parseFloat(last.toFixed(2)));
  });
  useMotionValueEvent(layer4ScrollY, 'change', (last) => {
    setLayer4SP(parseFloat(last.toFixed(2)));
  });

  //  Layer1 values
  const layer1BoxWidth = useTransform(
    layer1ScrollY,
    [0.77, 1],
    [window.innerWidth, visualWidth.get()],
  );

  const layer1BoxHeight = useTransform(
    layer1ScrollY,
    [0.77, 1],
    [window.innerHeight + 100, visualHeight.get()],
  );

  const layer1Radius = useTransform(
    layer1ScrollY,
    [0.77, 0.8, 1],
    [0, visualRadius.get(), visualRadiusFinal.get()],
  );

  const layer1ImgY = useTransform(
    layer1ScrollY,
    [0.77, 1],
    ['0%', isMobile ? '-20%' : '-10%'],
  );

  const layer1MarginBottom = useTransform(
    layer1ScrollY,
    [0.77, 1],
    ['0', `-${(window.innerHeight - visualHeight.get()) / 2}px`],
  );

  // Layer2 values
  const Layer2Feat1Y = useTransform(layer2ScrollY, [0.3, 0.95], [20, 0]);
  const Layer2Feat2Y = useTransform(layer2ScrollY, [0.3, 0.95], [120, 0]);
  const Layer2Feat3Y = useTransform(layer2ScrollY, [0.3, 0.95], [220, 0]);
  const Layer2FeatBox1Y = useTransform(layer2ScrollY, [0.4, 0.95], [20, 0]);
  const Layer2FeatBox2Y = useTransform(layer2ScrollY, [0.4, 0.95], [120, 0]);
  const Layer2FeatBox3Y = useTransform(layer2ScrollY, [0.4, 0.95], [220, 0]);
  const Layer2FeatBox4Y = useTransform(layer2ScrollY, [0.4, 0.95], [320, 0]);
  const Layer2FeatBox5Y = useTransform(layer2ScrollY, [0.4, 0.95], [420, 0]);
  const Layer2FeatBox6Y = useTransform(layer2ScrollY, [0.4, 0.95], [520, 0]);

  // Layer3 values
  const Layer3maskW = useTransform(
    layer3MaskScrollY,
    [0.05, 0.65],
    [window.innerWidth, visualWidth.get()],
  );
  const Layer3maskH = useTransform(
    layer3MaskScrollY,
    [0.05, 0.65],
    [window.innerHeight, visualHeight.get()],
  );
  const layer3maskR = useTransform(
    layer3MaskScrollY,
    [0.05, 0.1, 1],
    [0, visualRadius.get(), visualRadiusFinal.get()],
  );
  const layer3marbinB = useTransform(
    layer3MaskScrollY,
    [0.05, 0.65],
    ['0', `-${(window.innerHeight - visualHeight.get()) / 2}px`],
  );

  useEffect(() => {
    const handleResize = () => {
      const _innerWidth = window.innerWidth;
      const _innerHeight = window.innerHeight;
      if (_innerWidth < 768) {
        visualWidth.set(_innerWidth - 40);
        visualHeight.set(_innerWidth - 40);
        visualRadius.set(20);
        visualRadiusFinal.set(30);
      } else if (_innerWidth <= 1440) {
        visualWidth.set(_innerWidth * 0.6);
        visualHeight.set(_innerHeight * 0.6);
        visualRadius.set(130);
        visualRadiusFinal.set(30);
      } else if (_innerWidth <= 1920) {
        visualWidth.set(1050);
        visualHeight.set(730);
        visualRadius.set(130);
        visualRadiusFinal.set(30);
      } else {
        visualWidth.set(1440);
        visualHeight.set(800);
        visualRadius.set(200);
        visualRadiusFinal.set(60);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    layer1Ref,
    layer2Ref,
    layer3Ref,
    layer3MaskRef,
    layer4Ref,
    headerColor,
    bgColor,
    layer1SP,
    layer2SP,
    layer3SP,
    layer3MSP,
    layer4SP,
    layer1BoxWidth,
    layer1BoxHeight,
    layer1Radius,
    layer1ImgY,
    layer1MarginBottom,
    Layer2Feat1Y,
    Layer2Feat2Y,
    Layer2Feat3Y,
    Layer2FeatBox1Y,
    Layer2FeatBox2Y,
    Layer2FeatBox3Y,
    Layer2FeatBox4Y,
    Layer2FeatBox5Y,
    Layer2FeatBox6Y,
    Layer3maskW,
    Layer3maskH,
    layer3maskR,
    layer3marbinB,
  };
};
