import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

import styl from './styles.module.scss';
import Intro from './_Intro';
import Layer1 from './_Layer1';
import Layer2 from './_Layer2';
import Layer3 from './_Layer3';
import Layer4 from './_Layer4';
import Header from '../../components/module/Header';
import Footer from '../../components/module/Footer';
import { isMobileAtom, pageInitAtom } from '../../logics/atom';
import Economy from '@/pages/Web3/economy';

export default function Main() {
  const [pageInit, setPageInit] = useAtom(pageInitAtom);
  const [headerColor, setHeaderColor] = useState<'green' | 'black'>('green');
  const [isMobile] = useAtom(isMobileAtom);

  const handleAnimationEnd = () => {
    setPageInit(true);
    setHeaderColor('black');
  };

  useEffect(() => {
    if (isMobile) {
      setPageInit(true);
    }
    return () => {
      setPageInit(false);
    };
  }, [isMobile]);

  return (
    <>
      <Helmet>
        <title>CIETY for Web3</title>
        <body className="bg-gray-dark" />
      </Helmet>

      {!pageInit && <Intro />}

      {isMobile ? (
        <div>
          <div className={styl.header}>
            <Header color={headerColor} />
          </div>

          <Layer1 changeHeaderColor={setHeaderColor} />

          {pageInit && (
            <>
              <Layer2 />
              <Layer3 changeHeaderColor={setHeaderColor} />
              <Layer4 changeHeaderColor={setHeaderColor} />
              <Economy />
            </>
          )}
          <Footer />
        </div>
      ) : (
        <motion.div
          className={classNames(styl.scene, { [styl.full]: pageInit })}
          style={{
            top: '100%',
            borderRadius: '120px',
            marginBottom: '-120px',
          }}
          animate={{
            top: '0',
            borderRadius: 0,
            transition: { duration: 0.65, delay: 1.3 },
          }}
          onAnimationComplete={handleAnimationEnd}
        >
          <div className={styl.header}>
            <Header color={headerColor} />
          </div>

          <Layer1 changeHeaderColor={setHeaderColor} />
          <Layer2 />
          <Layer3 changeHeaderColor={setHeaderColor} />
          <Layer4 changeHeaderColor={setHeaderColor} />
          <Economy />
          <Footer />
        </motion.div>
      )}
    </>
  );
}
