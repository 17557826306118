import classNames from 'classnames';
import styl from './styles.module.scss';
import { Links } from '../../utils/outLinks';
import i18next from 'i18next';

interface ButtonProps {
  label: string;
  color?:
    | 'black'
    | 'green'
    | 'white'
    | 'skyblue'
    | 'brown'
    | 'red'
    | 'blue'
    | 'yellow'
    | 'purple'
    | 'pink'
    | 'blackAndWhite';
  variant?: 'default' | 'bold';
  marginTop?: number;
  link?: string;
  isForm?: boolean;
}

const Button = ({
  label,
  variant = 'default',
  color = 'black',
  marginTop,
  link = Links.login,
  isForm = false,
}: ButtonProps) => {
  return (
    <>
      {isForm ? (
        <button
          type="button"
          onClick={() => {
            location.href=link;
          }}
          className={classNames(
            styl.button,
            styl[color],
            styl[variant],
            styl[i18next.language],
          )}
          style={{ marginTop: marginTop && `${marginTop}px` }}
        >
          {label}
        </button>
      ) : (
        <a
          href={link}
          className={classNames(
            styl.button,
            styl[color],
            styl[variant],
            styl[i18next.language],
          )}
          style={{ marginTop: marginTop && `${marginTop}px` }}
        >
          {label}
        </a>
      )}
    </>
  );
};

export default Button;
