export const Links = {
  login: 'https://ciety.xyz/login',
  join: 'https://ciety.xyz/join',
  create: `https://ciety.xyz/google-connect/create`,
  createDev: `https://ciety.dev/google-connect/create`,

  about: 'https://ciety.xyz/@ciety/about',
  community: 'https://ciety.xyz/@ciety',
  faq: 'https://ciety.xyz/@ciety/faq',
  backers: 'https://ciety.xyz/@ciety/backers',
  contact: 'mailto:contact@ciety.io',

  twitter: 'https://twitter.com/ciety_xyz',
  instagram: 'https://www.instagram.com/ciety.xyz/',
  linkedIn: 'https://www.linkedin.com/company/ciety',

  medium: 'https://medium.com/@ciety',
  youtube: 'https://www.youtube.com/@ciety_xyz',
  friends: 'https://opensea.io/collection/ciety-friends',

  googlePlayStore:
    'https://play.google.com/store/apps/details?id=com.ciety.xyz.prod&pli=1',
  appleAppStore:
    'https://apps.apple.com/us/app/ciety-web3-game-community/id6443814305',
  webPlay: 'https://ciety.xyz/@ciety',

  ios: 'https://apps.apple.com/us/app/ciety/id6443814305',
  android:
    'https://play.google.com/store/apps/details?id=com.ciety.xyz.prod&pli=1',
  deskTop: 'https://apps.apple.com/us/app/ciety/id6443814305',
};
