import { motion, useScroll } from 'framer-motion';
import styl from '../Web3/styles.module.scss';

import ProfileComma from '@Images/profile/Profile-Comma.png';
import ProfileCiety from '@Images/profile/Profile-CIETY.png';
import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { onChange } from '../../utils/hooks';
import Chat from '@Components/core/Chat';
import { useAtom } from 'jotai/index';
import { isMobileAtom } from '@/logics/atom';
import classNames from 'classnames';
import i18n from 'i18next';

export default function Layer2() {
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);

  const [chat1Show, setChat1Show] = useState(false);
  const [chat2Show, setChat2Show] = useState(false);
  const [chat3Show, setChat3Show] = useState(false);
  const [chat4Show, setChat4Show] = useState(false);

  const chat1Ref = useRef(null);
  const chat2Ref = useRef(null);
  const chat3Ref = useRef(null);
  const chat4Ref = useRef(null);

  const { scrollYProgress: chat1Progress } = useScroll({
    target: chat1Ref,
    layoutEffect: false,
    offset: ['start end', 'end start'],
  });

  const { scrollYProgress: chat2Progress } = useScroll({
    target: chat2Ref,
    layoutEffect: false,
    offset: ['start end', 'end start'],
  });

  const { scrollYProgress: chat3Progress } = useScroll({
    target: chat3Ref,
    layoutEffect: false,
    offset: ['start end', 'end start'],
  });

  const { scrollYProgress: chat4Progress } = useScroll({
    target: chat4Ref,
    layoutEffect: false,
    offset: ['start end', 'end start'],
  });

  onChange(chat1Progress, (last) => {
    if (last > 0.35 && chat1Show === false) {
      setChat1Show(true);
    } else if (last < 0.35 && chat1Show === true) {
      setChat1Show(false);
    }
  });

  onChange(chat2Progress, (last) => {
    if (last > 0.35 && chat2Show === false) {
      setChat2Show(true);
    } else if (last < 0.35 && chat2Show === true) {
      setChat2Show(false);
    }
  });

  onChange(chat3Progress, (last) => {
    if (last > 0.35 && chat3Show === false) {
      setChat3Show(true);
    } else if (last < 0.35 && chat3Show === true) {
      setChat3Show(false);
    }
  });

  onChange(chat4Progress, (last) => {
    if (last > 0.35 && chat4Show === false) {
      setChat4Show(true);
    } else if (last < 0.35 && chat4Show === true) {
      setChat4Show(false);
    }
  });

  return (
    <div className={styl.layer2}>
      <div className="relative z-10">
        <div className={classNames(styl.text3, styl[i18n.language])}>
          <p>
            {isMobile ? t('web3.section2.text3M') : t('web3.section2.text3')}
          </p>
        </div>
        <div className={styl.bubbles}>
          <motion.div
            ref={chat1Ref}
            style={{ opacity: chat1Show ? 1 : 0, transition: 'opacity 0.35s' }}
          >
            <Chat thumb={ProfileComma} text={t('web3.section2.chat1')} />
          </motion.div>
          <motion.div
            ref={chat2Ref}
            style={{ opacity: chat2Show ? 1 : 0, transition: 'opacity 0.35s' }}
          >
            <Chat
              thumb={ProfileCiety}
              text={t('web3.section2.chat2')}
              isCiety
              isMe
            />
          </motion.div>
          <motion.div
            ref={chat3Ref}
            style={{ opacity: chat3Show ? 1 : 0, transition: 'opacity 0.35s' }}
          >
            <Chat thumb={ProfileComma} text={t('web3.section2.chat3')} />
          </motion.div>
          <motion.div
            ref={chat4Ref}
            style={{ opacity: chat4Show ? 1 : 0, transition: 'opacity 0.35s' }}
          >
            <Chat
              thumb={ProfileCiety}
              text={t('web3.section2.chat4')}
              isCiety
              isMe
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
