import classNames from 'classnames';
import styl from './styles.module.scss';
import { motion } from 'framer-motion';
import LandImg from '@Images/economy/land.png';
import KeyVisualMo1 from '@Images/economy/keyvisual1_mo.png';
import KeyVisualMo2 from '@Images/economy/keyvisual2_mo.png';
import KeyVisualMo3 from '@Images/economy/keyvisual3_mo.png';
import FeatCard1 from '@Images/economy/keyvisual1.png';
import FeatCard2 from '@Images/economy/keyvisual2.png';
import FeatCard3 from '@Images/economy/keyvisual3.png';
import FeatCard4 from '@Images/economy/keyvisual4.png';
import FeatCard5 from '@Images/economy/keyvisual5.png';
import FeatCard6 from '@Images/economy/keyvisual6.png';
import Community1 from '@Images/economy/Keyvisual-Community-Human.png';
import Community2 from '@Images/economy/Keyvisual-Community-Object.png';
import Marquee from 'react-fast-marquee';
import CommCard1 from '@Images/economy/community1.png';
import CommCard2 from '@Images/economy/community2.png';
import CommCard3 from '@Images/economy/community3.png';
import CommCard4 from '@Images/economy/community4.png';
import CommCard5 from '@Images/economy/community5.png';
import CommCard6 from '@Images/economy/community6.png';
import CommCard7 from '@Images/economy/community7.png';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai/index';
import { isMobileAtom } from '@/logics/atom';
import { useDesktopHelper } from '@/pages/Web3/economy/helpers';
import SubdomainForm from '@Components/common/subdomainForm';
import IconTitle from '@/pages/Web3/component/IconTitle';

const Economy = () => {
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);
  const {
    layer1Ref,
    layer2Ref,
    layer3Ref,
    layer3MaskRef,
    layer4Ref,
    bgColor,
    layer1BoxWidth,
    layer1BoxHeight,
    layer1Radius,
    layer1ImgY,
    layer1MarginBottom,
    Layer2Feat1Y,
    Layer2Feat2Y,
    Layer2Feat3Y,
    Layer2FeatBox1Y,
    Layer2FeatBox2Y,
    Layer2FeatBox3Y,
    Layer2FeatBox4Y,
    Layer2FeatBox5Y,
    Layer2FeatBox6Y,
    Layer3maskW,
    Layer3maskH,
    layer3maskR,
    layer3marbinB,
  } = useDesktopHelper();

  return (
    <div className={classNames(styl.layout, styl[bgColor])}>
      <motion.div
        className={styl.layer1}
        ref={layer1Ref}
        style={{ marginBottom: layer1MarginBottom }}
      >
        <div
          className={styl.titleWrapper}
          style={{ paddingTop: isMobile ? '160px' : '320px' }}
        >
          <IconTitle
            center={isMobile ? true : false}
            subTitle={'CIETY for Creator Economy'}
            title={t('web3.section7.title')}
            desc={isMobile ? t('web3.section7.descM') : t('web3.section7.desc')}
          />
        </div>

        <div className={styl.visual}>
          <div className={styl.sticky}>
            <motion.div
              className={styl.mask}
              style={{
                width: layer1BoxWidth,
                height: layer1BoxHeight,
                borderRadius: layer1Radius,
              }}
            >
              <div className={styl.img}>
                <motion.img
                  loading="lazy"
                  src={LandImg}
                  style={{ translateY: layer1ImgY, translateX: '-50%' }}
                />
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>

      <div className={styl.layer2} ref={layer2Ref}>
        <div className={styl.container}>
          <div className={styl.heading}>
            <IconTitle title={t('web3.section8.title')} desc={''} />
            <div className={styl.features}>
              {isMobile ? (
                <>
                  <p className={styl.feat}>
                    <p className={styl.num}>1.</p>
                    {t('web3.section8.feat1')}
                    <img src={KeyVisualMo1} alt="" />
                  </p>
                  <p className={styl.feat}>
                    <p className={styl.num}>2.</p>
                    {t('web3.section8.feat2')}
                    <img src={KeyVisualMo2} alt="" />
                  </p>
                  <p className={styl.feat}>
                    <p className={styl.num}>3.</p>
                    {t('web3.section8.feat3')}
                    <img src={KeyVisualMo3} alt="" />
                  </p>
                </>
              ) : (
                <>
                  <motion.p
                    className={styl.feat}
                    style={{ translateY: Layer2Feat1Y }}
                  >
                    <p className={styl.num}>1.</p>
                    {t('web3.section8.feat1')}
                  </motion.p>
                  <motion.p
                    className={styl.feat}
                    style={{ translateY: Layer2Feat2Y }}
                  >
                    <p className={styl.num}>2.</p>
                    {t('web3.section8.feat2')}
                  </motion.p>
                  <motion.p
                    className={styl.feat}
                    style={{ translateY: Layer2Feat3Y }}
                  >
                    <p className={styl.num}>3.</p>
                    {t('web3.section8.feat3')}
                  </motion.p>
                </>
              )}
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className={styl.featureCards}>
            <motion.img
              loading="lazy"
              src={FeatCard1}
              style={{ translateY: Layer2FeatBox1Y }}
            />
            <motion.img
              loading="lazy"
              src={FeatCard2}
              style={{ translateY: Layer2FeatBox2Y }}
            />
            <motion.img
              loading="lazy"
              src={FeatCard3}
              style={{ translateY: Layer2FeatBox3Y }}
            />
            <motion.img
              loading="lazy"
              src={FeatCard4}
              style={{ translateY: Layer2FeatBox4Y }}
            />
            <motion.img
              loading="lazy"
              src={FeatCard5}
              style={{ translateY: Layer2FeatBox5Y }}
            />
            <motion.img
              loading="lazy"
              src={FeatCard6}
              style={{ translateY: Layer2FeatBox6Y }}
            />
          </div>
        )}
      </div>

      <motion.div
        ref={layer3Ref}
        className={styl.layer3}
        style={{ marginBottom: layer3marbinB }}
      >
        <div className={classNames(styl.container, styl.titleWrapper)}>
          <IconTitle
            title={t('web3.section9.title')}
            desc={isMobile ? t('web3.section9.descM') : t('web3.section9.desc')}
          />
        </div>

        <div className={styl.image} ref={layer3MaskRef}>
          <div className="sticky top-0 h-screen">
            <motion.div
              className={classNames(styl.mask, {
                [styl.purple]: bgColor === 'black',
              })}
              initial={{ borderRadius: 0 }}
              style={{
                width: Layer3maskW,
                height: Layer3maskH,
                borderRadius: layer3maskR,
              }}
            >
              <img loading="lazy" src={Community1} />
              <img loading="lazy" src={Community2} />
            </motion.div>
          </div>
        </div>
      </motion.div>

      <div className={styl.layer4} ref={layer4Ref}>
        <div className={styl.container}>
          <div className={styl.heading}>
            <h2>{t('web3.section10.title')}</h2>
            <p>
              {isMobile ? t('web3.section10.descM') : t('web3.section10.desc')}
            </p>
          </div>
          <SubdomainForm
            toolTipColor={'white'}
            label={t('web3.section10.buttonLabel')}
            color={'green'}
          />
        </div>
        <div className={styl.image}>
          <Marquee className={styl.wrap}>
            <img src={CommCard1} alt="" />
            <img src={CommCard2} alt="" />
            <img src={CommCard3} alt="" />
            <img src={CommCard4} alt="" />
            <img src={CommCard5} alt="" />
            <img src={CommCard6} alt="" />
            <img src={CommCard7} alt="" />
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Economy;
