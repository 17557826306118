import styl from './styles.module.scss';
import classNames from 'classnames';
import { useAtom } from 'jotai/index';
import { isMobileAtom } from '@/logics/atom';
import i18n from 'i18next';

interface IconTitleProps {
  icon?: React.ReactNode;
  subTitle?: string;
  title: string;
  desc: string;
  center?: boolean;
}

const IconTitle = ({
  icon,
  subTitle,
  title,
  desc,
  center = true,
}: IconTitleProps) => {
  const [isMobile] = useAtom(isMobileAtom);

  return (
    <div className={classNames(styl.iconTitleContainer, center && styl.center)}>
      {icon && <div className={styl.iconWrapper}>{icon}</div>}

      {!isMobile && <p className={styl.subTitle}>{subTitle}</p>}
      <p className={classNames(styl.title, styl[i18n.language])}>{title}</p>
      <p className={styl.desc}>{desc}</p>
    </div>
  );
};

export default IconTitle;
