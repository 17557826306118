import { GiftokPlayerSeq, GiftokMo } from './_giftokImages';
import { useSequences } from '../../utils/hooks';
import { useAtom } from 'jotai';
import { isMobileAtom } from '../../logics/atom';
import GiftokWebp from '../../assets/animation/Giftok-PC-1400.webp';
import GiftokWebpM from '../../assets/animation/Giftok-Mobile.webp';

export default function GiftokPlayer({ containerRef }) {
  const [isMobile] = useAtom(isMobileAtom);
  const [webp, loaded] = useSequences(isMobile ? [GiftokWebpM] : [GiftokWebp]);

  return (
    <div>
      {loaded ? (
        <img src={webp[0]} />
      ) : (
        <img src={isMobile ? GiftokPlayerSeq[0] : GiftokMo[0]} alt="" />
      )}
    </div>
  );
}
