import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as en from './en';
import * as kr from './kr';

const resources = { kr, en };

i18n.use(initReactI18next).init({
  lng: 'kr',
  fallbackLng: 'kr',
  resources,
  // debug: true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    useSuspense: true,
    // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  },
});

export default i18n;
