import { useMemo, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  motion,
  useMotionTemplate,
  useScroll,
  useTransform,
} from 'framer-motion';
import classNames from 'classnames';

import CleverTurn from '@Components/module/CleverTurn';
import CommaTurn from '@Components/module/CommaTurn';
import Cut from '@Components/core/Cut';
import {
  isMobileAtom,
  pageInitAtom,
  screenHeightAtom,
} from '../../logics/atom';

import { CommaTurnSeq } from '../../assets/sequences/comma_turn';
import { CommaZoom } from '../../assets/sequences/comma-zoom';
import { CleverTurnSeq } from '../../assets/sequences/clever_turn';

import styl from '../Web3/styles.module.scss';
import SectionHeader from '@Components/module/SectionHeader';
import { onChange } from '../../utils/hooks';
import SubdomainForm from '@Components/common/subdomainForm';

export default function Layer1({ changeHeaderColor }) {
  const [pageInit, setPageInit] = useAtom(pageInitAtom);

  const { t } = useTranslation();
  const layer1Ref = useRef<HTMLDivElement | null>(null);
  const [isMobile] = useAtom(isMobileAtom);
  const [screenHeight] = useAtom(screenHeightAtom);
  const commaSource = useMemo(
    () => [...CommaTurnSeq, ...CommaZoom],
    [CommaTurnSeq, CommaZoom],
  );
  const duration = screenHeight * 2;

  const [textIndex, setTextIndex] = useState(1);
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    target: layer1Ref,
    offset: ['start start', 'end end'],
  });

  const scale = useTransform(scrollYProgress, [0.45, 0.8], [0, 1]);
  const borderRadius = useTransform(scrollYProgress, [0.45, 0.8], [80, 0]);
  const opacity = useTransform(scrollYProgress, [0.45, 0.8], [0.4, 1]);
  const backgroundColor = useMotionTemplate`rgba(178, 255, 0, ${opacity})`;
  const textOpacity = useTransform(scrollYProgress, [0.3, 0.5], [0, 1]);
  const screen = { scale, borderRadius, backgroundColor };
  const text = { opacity: textOpacity, index: textIndex };

  onChange(scrollYProgress, (last) => {
    if (last > 0.8 && textIndex === 1) {
      setTextIndex(2);
      changeHeaderColor('green');
    } else if (last < 0.8 && textIndex === 2) {
      setTextIndex(1);
      changeHeaderColor('black');
      setPageInit(true);
    }
  });

  return (
    <div className="relative">
      <div ref={layer1Ref} className={styl.scene1} style={{ height: duration }}>
        <div className={styl.textLayer}>
          <div className={styl.heading}>
            <SectionHeader
              white={true}
              title={
                isMobile
                  ? t('web3.section1.headingM')
                  : t('web3.section1.heading')
              }
              desc={t('web3.section1.desc')}
            />
          </div>
          <SubdomainForm
            toolTipColor={'white'}
            color={'green'}
            label={
              isMobile
                ? t('web3.section1.buttonLabelM')
                : t('web3.section1.buttonLabel')
            }
            center
          />
        </div>

        <Cut className={styl.visualLayer} duration={duration}>
          <div className={styl.characters}>
            <CommaTurn source={commaSource} targetRef={layer1Ref} />
            {!isMobile && (
              <CleverTurn source={CleverTurnSeq} containerRef={layer1Ref} />
            )}
          </div>
          <motion.div
            className={styl.greenScreen}
            style={{ ...screen, translateX: '-50%', translateY: '-50%' }}
          >
            <motion.div
              style={{ opacity: text.opacity }}
              className={classNames(styl.text2)}
            >
              {text.index === 1 ? (
                isMobile ? (
                  <img
                    alt={'ciety_text_1'}
                    src={require(`@Images/${t('web3.section2.textImage1M')}`)}
                    data-index="1"
                  />
                ) : (
                  <img
                    alt={'ciety_text_1'}
                    src={require(`@Images/${t('web3.section2.textImage1')}`)}
                    data-index="1"
                  />
                )
              ) : isMobile ? (
                <img
                  alt={'ciety_text_2'}
                  src={require(`@Images/${t('web3.section2.textImage2M')}`)}
                  data-index="2"
                />
              ) : (
                <img
                  alt={'ciety_text_2'}
                  src={require(`@Images/${t('web3.section2.textImage2')}`)}
                  data-index="2"
                />
              )}
            </motion.div>
          </motion.div>
        </Cut>
      </div>
    </div>
  );
}
