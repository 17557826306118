const category = {
  All: '전체',
  etc: 'etc',
  Game: '게임',
  Photography: '사진',
  Lifestyle: '라이프스타일',
  Movie: '영화',
  Economy: '경제',
  Education: '교육',
  Kpop: 'KPop',
  Politic: '정치',
  Science: '과학',
  Food: '음식',
  Pet: '반려동물',
  Music: '음악',
  'Health / Sports': '건강/스포츠',
  IT: 'IT',
  'Creative Writing': '문예창작',
  Travel: '여행',
  'Art / Design': '예술/디자인',
  'Faith / Religion': '신앙/종교',
  'Fashion / Beauty': '패션/뷰티',
};

export default {
  category,
};
