import React from 'react';
import { PrerenderedComponent } from 'react-prerendered-component';

export default function PreRenderedLoadable({ loadableComp: LoadableComp }) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <PrerenderedComponent live={LoadableComp.load()}>
      <LoadableComp />
    </PrerenderedComponent>
  );
}
