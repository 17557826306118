import { useState } from 'react';
import {
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';
import styl from './styles.module.scss';
import { useSequences } from '../../utils/hooks';
import { responsive } from '../../utils/func';
import { useAtom } from 'jotai/index';
import { isMobileAtom } from '../../logics/atom';

export default function CommaTurn({ source, targetRef }) {
  const [sequence, loaded] = useSequences(source);
  const [isMobile] = useAtom(isMobileAtom);

  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    target: targetRef,
    offset: ['start start', 'end end'],
  });
  const [currentIndex, setIndex] = useState(0);
  const index = useTransform(
    scrollYProgress,
    [0.05, 0.5, 0.6, 1],
    [0.05, 79, 79, 118],
  );
  const startX = isMobile ? '50%' : responsive('15%', '15%', '15%');
  const endX = isMobile ? '50%' : responsive('50%', '50%', '50%');
  const rotate = useTransform(scrollYProgress, [0.5, 0.3], [0, -7.5]);
  const left = useTransform(scrollYProgress, [0.05, 0.3], [startX, endX]);
  const opacity = useTransform(scrollYProgress, [0.75, 0.8], [1, 0]);
  const translateY = useTransform(
    scrollYProgress,
    [0.05, 0.3, 0.6, 0.8],
    ['0%', '10%', '10%', '45%'],
  );

  useMotionValueEvent(index, 'change', (last) => setIndex(Math.round(last)));
  return (
    <motion.div className={styl.comma} style={{ left, opacity }}>
      <motion.div className={styl.sequence} style={{ rotate, translateY }}>
        {loaded ? (
          <motion.img src={sequence[currentIndex]} />
        ) : (
          <img src={source[0]} alt="" />
        )}
      </motion.div>
    </motion.div>
  );
}
