import { motion, useTransform } from 'framer-motion';
import { useAtom } from 'jotai';

import styl from './styles.module.scss';

import MerchImg1 from '@Images/store/Keyvisual-Merch-1.png';
import MerchImg2 from '@Images/store/Keyvisual-Merch-2.png';
import MerchImg3 from '@Images/store/Keyvisual-Merch-3.png';
import CommaCartAni from '../../assets/animation/6-Comma-Shopping-1400.webp';

import { makeScroll } from '../../utils/func';
import { useRange } from '../../utils/hooks';
import { isMobileAtom } from '../../logics/atom';
import { useTranslation } from 'react-i18next';

export default function Merch({ containerRef }) {
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);
  const storeScroll = makeScroll(['start end', 'end end']);
  const { scrollYProgress: storeProgress } = storeScroll(containerRef);

  const merch1X = useRange(
    storeProgress,
    [
      [0.7, 0.9],
      ['0%', '-150%'],
    ],
    [
      [0.7, 0.9],
      ['-55%', '-150%'],
    ],
  );
  const merch2X = useRange(
    storeProgress,
    [
      [0.1, 0.35, 0.75, 0.95],
      ['40%', '0%', '0%', '-150%'],
    ],
    [
      [0.7, 0.9],
      ['-50%', '100%'],
    ],
  );

  const merch3X = useRange(
    storeProgress,
    [
      [0.1, 0.35, 0.8, 1],
      ['80%', '0%', '0%', '-150%'],
    ],
    [
      [0.7, 0.9],
      ['-50%', '-130%'],
    ],
  );

  const commaX = useRange(
    storeProgress,
    [
      [0.1, 0.35, 0.7, 1],
      ['120%', '30%', '35%', '120%'],
    ],
    [
      [0.1, 0.35, 0.7, 1],
      ['120%', '0%', '-20%', '150%'],
    ],
  );

  const text1OP = isMobile
    ? 0
    : useTransform(storeProgress, [0.3, 0.31, 0.6, 0.9], [0, 1, 1, 0]);
  const text2OP = isMobile
    ? 0
    : useTransform(storeProgress, [0.4, 0.41, 0.6, 0.9], [0, 1, 1, 0]);
  const text3OP = isMobile
    ? 0
    : useTransform(storeProgress, [0.5, 0.51, 0.6, 0.9], [0, 1, 1, 0]);

  return (
    <motion.div className={styl.store} style={{}}>
      <div className={styl.merches}>
        <motion.img src={MerchImg1} style={{ translateX: merch1X }} />
        <motion.img src={MerchImg2} style={{ translateX: merch2X }} />
        <motion.img src={MerchImg3} style={{ translateX: merch3X }} />

        {!isMobile && (
          <div className={styl.txts}>
            <motion.p className={styl.p1} style={{ opacity: text1OP }}>
              {t('web3.section6.chat1')}
            </motion.p>
            <motion.p className={styl.p2} style={{ opacity: text2OP }}>
              {t('web3.section6.chat2')}
            </motion.p>
            <motion.p className={styl.p3} style={{ opacity: text3OP }}>
              {t('web3.section6.chat3')}
            </motion.p>
          </div>
        )}
      </div>

      <motion.img
        src={CommaCartAni}
        className={styl.cartAnime}
        style={{ left: commaX }}
      />
    </motion.div>
  );
}
