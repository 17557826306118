const IMAGE_BASE_PATH_PC = 'home/pc/';
const IMAGE_BASE_PATH_MO = 'home/mobile/';

const section1 = {
  title:
    '유튜브 크리에이터를 위한\n' +
    '커뮤니티 플랫폼,\n' +
    '사이어티에서 시작하세요!',
  desc: 'Community Platform for Creators\n' + 'Your World, Your CIETY',
  buttonLabel: '커뮤니티 만들기',
  buttonLabelM: '커뮤니티 시작하기',
  toolTip:
    '사이어티 서브 도메인을 생성하고,\n' + '내 커뮤니티를 바로 만들어 보세요!',
};

const section2 = {
  title:
    '뉴비를 찐 팬으로!\n' +
    '즐거운 순간들이 모여\n' +
    '자연스럽게 깊어지는 내 세상',
};

const section3 = {
  subTitle: "Let's start a community!",
  title:
    '커뮤니티를 시작한다는 건,\n' +
    '내 찐 팬들을 모으고\n' +
    '이들과 소통할 수 있다는 것!',
  titleM: '커뮤니티를 한다는 건,\n' + '찐팬들과 소통할 수 있다는 것!',
  desc:
    '팬덤과의 소통, 아직도 짤막한 댓글로만 나누세요?\n' +
    '다양한 소통 방식으로 내 팬들과 함께 커뮤니티를 채워나가고,\n' +
    '팬덤의 니즈와 아이디어를 반영해 콘텐츠나 상품도 만들어 보세요!',
  descM:
    '팬과의 소통, 아직도 댓글로만 나누세요?\n' +
    '다양한 소통 방식으로 함께 커뮤니티를 채워나가고,\n' +
    '팬덤의 니즈를 반영한 콘텐츠와 상품을 만들어요!',
  buttonLabel: 'Get started for free',
};

const section4 = {
  subTitle: 'Hello. Youtube creator',
  title: '유튜브 크리에이터가\n' + '만들기 딱 좋은 커뮤니티!',
  desc:
    '단순 채널 공지부터, 자유로운 이야기, 유료 콘텐츠 판매와 굿즈 판매까지 —\n' +
    '구독자와 함께 이야기하는 공간이 생긴다면? 유튜브 채널의 찐 팬을 모아보세요!',
  descM:
    '공지나 사소한 이야기, 유료 콘텐츠, 굿즈 판매까지\n' +
    '유튜브 채널의 찐 팬을 모아보세요!',
  buttonLabel: 'Get started for free',
  messages: [
    [
      '저번에 추천해주신 게임 해봤는데\n' + '완전 재밌더라고요!!!',
      '오늘 7시에 라이브 있으니까\n' + '사이어티에서 만나요!',
      '혹시 오늘 라방 \n' + '몇 시인지 아는 분?',
      '새로 출시한 게임도 리뷰해 주세요!!\n' + '완전 궁금해요!',
      '한정판 굿즈...\n' + '재판매 안되는건가용??? ㅠㅠ',
    ],
    [
      '오늘 라이브 룩\n' + '진짜 취향저격이에요!',
      '장갑이랑 모자도\n' + '굿즈 만들어주세요!!ㅠㅠ',
      '지금 라이브 중, 어서오세요!\n' + '여름 티셔츠 하울 소개합니다!',
      '오늘 메이크업도 궁금해요!\n' + '아이쉐도우 제품 정보 알려주세요',
      '커뮤니티 티셔츠 굿즈 최고!\n' + '바로 구매하러 갑니다!',
    ],
    [
      '잠이 솔솔 오는 목소리\n' + '나는가수다 나가셔야 해...',
      '라이브 진짜 찐이다..\n' + '귀가 살살 녹아요!',
      '콘서트 열어주세요ㅠㅠ\n' + '티켓 꼭 살거에요..!!',
      '오늘 몇 시까지 라이브 하시나요?\n' + '친구들 다 초대할 거예요!!',
      '신청곡 이벤트가 있어요.\n' + '노래 불러드리고 굿즈도 보내드릴게요!',
    ],
  ],
  messagesM: [
    [
      '혹시 오늘 라방 \n' + '몇 시인지 아는 분?',
      '오늘 7시에 라이브 있으니까\n' + '사이어티에서 만나요!',
      '한정판 굿즈...\n' + '재판매 원해요 ㅠㅠ',
    ],
    [
      '오늘 라이브 룩\n' + '진짜 취향저격이에요!',
      '지금 라이브 중, 어서오세요!\n' + '여름 티셔츠 하울 소개합니다!',
      '커뮤니티 티셔츠 굿즈 최고!\n' + '바로 구매하러 갑니다!',
    ],
    [
      '라이브 진짜 찐이다..\n' + '귀가 살살 녹아요!',
      '콘서트 열어주세요ㅠㅠ\n' + '티켓 꼭 살거에요..!!',
      '신청곡 이벤트가 있어요.\n' + '노래부르고 굿즈 보내드릴게요!',
    ],
  ],
  images: [
    `${IMAGE_BASE_PATH_PC}Section6-img1.png`,
    `${IMAGE_BASE_PATH_PC}Section6-img2.png`,
    `${IMAGE_BASE_PATH_PC}Section6-img3.png`,
  ],
};

const section5 = {
  subTitle: 'for your Real Fandom',
  title:
    '내 유튜브 채널\n' +
    '구독자만 입장 가능한\n' +
    '내 찐 팬 커뮤니티도 만들 수 있어요!',
  titleM: '유튜브 채널 구독자만\n' + '입장 가능한 커뮤니티!',
  desc:
    '사이어티는 유튜브 계정 연동으로 커뮤니티 가입 조건을 설정할 수 있어요.\n' +
    '누구나 입장 가능한 오픈 커뮤니티 개설도 당연히 가능해요!',
  descM:
    '유튜브 계정 연동으로 커뮤니티 가입 조건 설정,\n' +
    '누구나 입장 가능한 오픈 커뮤니티 개설도 가능해요!',
  buttonLabel: 'Get started for free',
  imageLabels: ['비구독자', '구독자', '멤버십', '파워멤버십'],
};

const section6 = {
  subTitle: 'from creator to Brand Growth',
  title:
    '내 커뮤니티에서 \n' +
    '구독자의 니즈를 파악해\n' +
    '굿즈 제작부터 판매까지!',
  titleM: '구독자의 니즈를 파악해\n' + '굿즈 제작부터 판매까지!',
  desc:
    '사이어티에서 자유롭게 쌓이는 다채로운 이야기들 속에서 \n' +
    '구독자들의 다양한 니즈를 발견할 수 있어요. \n' +
    '구독자의 니즈에서 인사이트를 얻어 굿즈를 기획하고, 출시해 보세요!\n' +
    '굿즈 제작부터 판매, 배송까지 사이어티에선 모두 가능해요!',
  descM:
    '구독자의 니즈에서 인사이트를 얻어\n' +
    '굿즈를 기획하고, 판매해 보세요.\n' +
    '굿즈 제작부터 판매, 배송까지 모두 가능해요!',
  buttonLabel: 'Get started for free',
};

const section7 = {
  subTitle: 'Exclusive Live Streaming',
  title:
    '내가 원하는\n' + '커뮤니티 멤버들에게만\n' + '독점 라이브 스트리밍 가능!',
  titleM: '커뮤니티 멤버들에게만\n' + '독점 라이브 스트리밍 가능!',
  desc:
    '사이어티에서는 커뮤니티 멤버십에 가입한 멤버들 대상\n' +
    '혹은 특정 멤버들 대상으로 라이브 스트리밍 송출이 가능해요!\n' +
    '내 커뮤니티 멤버와 더 가깝게 소통하세요!',
  descM:
    '커뮤니티 멤버십에 가입한 멤버들 대상 혹은\n' +
    '특정 멤버들 대상으로 라이브 스트리밍 송출이 가능해요!',
  buttonLabel: 'Get started for free',
  messages: [
    '진짜 띵곡.........bbb',
    '이 노래를 라이브로 듣다니..\n' + '감동 또 감동...',
    '이 노래는 앨범 미수록곡이네요!!!',
    '와 이걸 사이어티에서 보네',
    '제가 이 노래를 얼마나 기다렸는데요ㅠㅠ',
    '오늘 진짜 너무 좋아요!',
    '굿즈 티셔츠 구매했는 데 너무 예뻐요~!',
    '이 노래를 라이브로 듣다니..\n' + '감동 또 감동...,',
    '이 노래는 앨범 미수록곡이네요!!!',
  ],
};

const section8 = {
  subTitle: 'Contents Commerce in Community',
  title: '고퀄리티 유료 콘텐츠와\n' + '유료 클래스 판매까지!',
  titleM: '고퀄리티 유료 콘텐츠와\n' + '유료 클래스 판매까지!',
  desc:
    '나를 좋아하고, 관심 있는 구독자들과 함께 사이어티에서 소통하며\n' +
    '고퀄리티 콘텐츠를 제작하고 온라인 클래스를 쉽게 판매해 보세요.\n' +
    '다른 플랫폼 없이 사이어티 커뮤니티에서 모두 가능해요!',
  descM:
    '콘텐츠를 제작하고 온라인 클래스를 판매해 보세요.\n' +
    '다른 플랫폼 없이 사이어티 커뮤니티에서 모두 가능해요!',
  buttonLabel: 'Get started for free',
};

const section9 = {
  subTitle: 'Direct Message',
  title:
    '1:1 다이렉트 메시지로\n' + '팬들에게 더 깊은\n' + '소속감을 선물하세요!',
  titleM:
    '1:1 다이렉트 메시지로\n' + '팬들에게 더 깊은\n' + '소속감을 선물하세요!',
  desc:
    '커뮤니티 내 대화하고 싶은 멤버와 자유롭게 대화할 수 있다면,\n' +
    '커뮤니티의 결속력은 더 단단해질 거예요!\n' +
    '1:1 혹은 그룹으로 멤버와 크리에이터와 다이렉트 메시지!',
  descM:
    '대화하고 싶은 멤버와 자유롭게 대화할 수 있다면,\n' +
    '커뮤니티의 결속력은 더 단단해질 거예요!\n' +
    '멤버끼리 혹은 크리에이터와도 다이렉트 메시지!',
  buttonLabel: 'Get started for free',
};

const section10 = {
  subTitle: 'Community Member Permission',
  title:
    '내가 만든 커뮤니티에서는\n' +
    '그룹 생성도 무제한으로,\n' +
    '멤버 권한 설정도 자유롭게!',
  titleM: '멤버 그룹 생성 무제한,\n' + '권한 설정도 자유롭게!',
  desc:
    '비구독자, 구독자, 유튜브 채널 멤버십도 단계별로 구분해 커뮤니티를 \n' +
    '다양한 그룹으로 관리할 수 있고, 내 마음대로 멤버 그룹을 조합하여 \n' +
    '보다 섬세하게 커뮤니티를 관리할 수 있어요.\n' +
    '또한, 그룹 별로 권한 설정을 다르게 설정하여 특별한 소통이 가능해요!',
  descM:
    '비구독자, 구독자, 멤버십 구분해 커뮤니티를 \n' +
    '다양한 그룹 별로 권한 설정을 다르게 설정하여\n' +
    '특별한 소통과 관리가 가능해요!',
  buttonLabel: 'Get started for free',
  circleTaps: ['비구독자', '구독자', '멤버십', '파워 멤버십'],
  checkList: [
    '게시물 읽기, 쓰기',
    '댓글, 이모지 사용',
    '게시물 읽기, 쓰기',
    '유료 콘텐츠 공개 / 구매',
    '한정판 굿즈 구매',
    '다이렉트 메시지',
    '커뮤니티 멤버 목록보기',
    '라이브스트리밍',
    '커뮤니티 매니저 권한',
  ],
};

const section11 = {
  title: '나에게 꼭 필요한 커뮤니티,\n' + '사이어티에서 지금 바로 시작하세요!',
  titleM: '나에게 꼭 필요한 커뮤니티,\n' + '사이어티에서 시작하세요!',
  desc: 'Community Platform for Creators\n' + 'Your World, Your CIETY',
  buttonLabel: '커뮤니티 만들기',
  cards: [
    {
      title: '커뮤니티 디자인 커스텀',
      desc:
        '프로필 / 커버 / 나만의 컬러 커스텀으로\n' +
        '내 아이덴티티가 한 눈에 보이는 커뮤니티',
    },
    {
      title: '커뮤니티 가입 조건 생성',
      desc:
        '유튜브 계정 연동으로 구독자만 입장 가능한\n' +
        '우리만의 커뮤니티부터 오픈형 커뮤니티까지',
    },
    {
      title: '굿즈 제작 및 판매',
      desc: '커뮤니티 관련\n' + '굿즈 제작 및 판매 가능',
    },
    {
      title: '라이브 스트리밍',
      desc:
        '커뮤니티 멤버 / 특정 멤버 그룹 대상으로\n' +
        '라이브 스트리밍 송출 가능 (유튜브 동시 송출 가능)',
    },
    {
      title: '유료 콘텐츠 판매',
      desc:
        '커뮤니티 멤버 / 특정 멤버들을 대상으로\n' + '유료 콘텐츠 판매 가능',
    },
    {
      title: '제약없는 커뮤니케이션',
      desc:
        '텍스트, 이미지, 영상, 이모지, 링크, NFT 등\n' +
        '형식 제약 없는 커뮤니케이션 가능',
    },
    {
      title: '다이렉트 메시지',
      desc:
        '다이렉트 메시지 기능을 통해\n' +
        '구독자와 크리에이터 혹은 원하는 멤버 그룹 소통',
    },
    {
      title: '자유로운 멤버 / 그룹 관리',
      desc:
        '자유롭게 멤버 그룹을 만들어 관리,\n' +
        '그룹 별로 권한 설정을 섬세하게 조정 가능',
    },
    {
      title: '유튜브 채널 영상 마이그레이션',
      desc:
        '유튜브 계정 연동으로 내 채널 영상이\n' +
        '커뮤니티에 자동 업로드 / 자연스러운 호환',
    },
  ],
};

const section12 = {
  title: '그 누구보다도 차별화된\n' + '커뮤니티 플랫폼, 사이어티',
  desc: '사이어티는 다양한 크리에이터 분들을 위해 지속적인 기능 고도화를 이뤄내고 있어요.',
  descM:
    '사이어티는 다양한 크리에이터 분들을 위해\n' +
    ' 지속적인 기능 고도화를 이뤄내고 있어요.',
};

const section13 = {
  title: '언제 어디에서나\n' + '사이어티와 함께!',
  desc: 'Community Platform for Creators\n' + 'Your World, Your CIETY',
  ios: { title: 'iOS', label: 'App Store 다운로드' },
  android: { title: 'Android', label: 'Google Play 다운로드' },
  browser: { title: 'Web Browser', label: '웹브라우저에서 열기' },
  deskApp: { title: 'Desk App', label: 'MacOS 10.13 (High Sierra) 이상' },
  buttonLabel: '커뮤니티 만들기',
};

export default {
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
  section10,
  section11,
  section12,
  section13,
};
