import { Links } from '@/utils/outLinks';
import styl from './styles.module.scss';
import { Info } from '@Components/svg';
import { useAtom } from 'jotai/index';
import { isMobileAtom } from '@/logics/atom';
import { useState } from 'react';
import Button from '@Components/common/button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const isProd = process.env.NODE_ENV === 'production';

const SubdomainForm = ({
  label,
  color = 'blackAndWhite',
  center = false,
  toolTipColor = 'black',
}: {
  label: string;
  color?: 'green' | 'blackAndWhite';
  center?: boolean;
  toolTipColor?: 'black' | 'white';
}) => {
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      location.href = `${isProd ? Links.create : Links.createDev}?subdomain=${inputValue}`;
    }
  };

  return (
    <form
      className={center ? styl.center : ''}
      onSubmit={(e) => e.preventDefault()}
    >
      {!isMobile && (
        <div className={styl.inputContainer}>
          <div className={styl.inputWrapper}>
            <p className={styl.root}>ciety.xyz/</p>
            <input
              type={'text'}
              placeholder={'yourworld'}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <Info
            innerColor={'white'}
            color={'#B1B1B1'}
            onClick={() => setIsOpen(!isOpen)}
          />
          <div
            className={classNames(
              styl.toolTip,
              toolTipColor === 'white' ? styl.white : '',
            )}
            style={{ display: isOpen ? 'block' : 'none' }}
          >
            {t('home.section1.toolTip')}
          </div>
        </div>
      )}
      <Button
        isForm
        label={label}
        link={
          isMobile
            ? Links.login
            : `${
                isProd ? Links.create : Links.createDev
              }?subdomain=${inputValue}`
        }
        marginTop={0}
        color={color}
        variant={'bold'}
      />
    </form>
  );
};

export default SubdomainForm;
