import Seq00 from './S01-Culliver_00000_50.webp';
import Seq01 from './S01-Culliver_00001_50.webp';
import Seq02 from './S01-Culliver_00002_50.webp';
import Seq03 from './S01-Culliver_00003_50.webp';
import Seq04 from './S01-Culliver_00004_50.webp';
import Seq05 from './S01-Culliver_00005_50.webp';
import Seq06 from './S01-Culliver_00006_50.webp';
import Seq07 from './S01-Culliver_00007_50.webp';
import Seq08 from './S01-Culliver_00008_50.webp';
import Seq09 from './S01-Culliver_00009_50.webp';
import Seq10 from './S01-Culliver_00010_50.webp';
import Seq11 from './S01-Culliver_00011_50.webp';
import Seq12 from './S01-Culliver_00012_50.webp';
import Seq13 from './S01-Culliver_00013_50.webp';
import Seq14 from './S01-Culliver_00014_50.webp';
import Seq15 from './S01-Culliver_00015_50.webp';
import Seq16 from './S01-Culliver_00016_50.webp';
import Seq17 from './S01-Culliver_00017_50.webp';
import Seq18 from './S01-Culliver_00018_50.webp';
import Seq19 from './S01-Culliver_00019_50.webp';
import Seq20 from './S01-Culliver_00020_50.webp';
import Seq21 from './S01-Culliver_00021_50.webp';
import Seq22 from './S01-Culliver_00022_50.webp';
import Seq23 from './S01-Culliver_00023_50.webp';
import Seq24 from './S01-Culliver_00024_50.webp';
import Seq25 from './S01-Culliver_00025_50.webp';
import Seq26 from './S01-Culliver_00026_50.webp';
import Seq27 from './S01-Culliver_00027_50.webp';
import Seq28 from './S01-Culliver_00028_50.webp';
import Seq29 from './S01-Culliver_00029_50.webp';
import Seq30 from './S01-Culliver_00030_50.webp';
import Seq31 from './S01-Culliver_00031_50.webp';
import Seq32 from './S01-Culliver_00032_50.webp';
import Seq33 from './S01-Culliver_00033_50.webp';
import Seq34 from './S01-Culliver_00034_50.webp';
import Seq35 from './S01-Culliver_00035_50.webp';
import Seq36 from './S01-Culliver_00036_50.webp';
import Seq37 from './S01-Culliver_00037_50.webp';
import Seq38 from './S01-Culliver_00038_50.webp';
import Seq39 from './S01-Culliver_00039_50.webp';

export const CleverTurnSeq = Array.of(
  Seq00,
  Seq01,
  Seq02,
  Seq03,
  Seq04,
  Seq05,
  Seq06,
  Seq07,
  Seq08,
  Seq09,
  Seq10,
  Seq11,
  Seq12,
  Seq13,
  Seq14,
  Seq15,
  Seq16,
  Seq17,
  Seq18,
  Seq19,
  Seq20,
  Seq21,
  Seq22,
  Seq23,
  Seq24,
  Seq25,
  Seq26,
  Seq27,
  Seq28,
  Seq29,
  Seq30,
  Seq31,
  Seq32,
  Seq33,
  Seq34,
  Seq35,
  Seq36,
  Seq37,
  Seq38,
  Seq39,
);
