import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Bars, MobileLogo, Close, SmallArrow } from '../svg';
import styles from './styles.module.scss';
import { Links } from '../../utils/outLinks';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { isMobileAtom } from '../../logics/atom';
import i18n from 'i18next';

const CIETY_LG_80 = '#CDFF8C';
const CIETY_BK = '#1C1C24';
const CIETY_LG = '#b2ff00';

interface HeaderProps {
  color?: string;
}

function useHelper() {
  const { t } = useTranslation();
  const navigations = useMemo(() => {
    return [
      { label: t('nav.home'), to: '/' },
      { label: t('nav.creator'), to: '/creator' },
      { label: t('nav.web3'), to: '/web3' },
      { label: t('nav.world'), to: '/worlds' },
      { label: t('nav.faq'), to: '/faq' },
    ];
  }, []);

  return { navigations };
}

function same(a, b) {
  return a === b;
}

function Wrapper({ color, mobileOpen, children }) {
  const classname = useMemo(() => {
    return classNames(styles.header, {
      [styles.black]: color === 'black',
    });
  }, [color]);

  return <div className={classname}>{children}</div>;
}

export default function Header({ color = 'green' }: HeaderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  const location = useLocation();
  const [mobileOpen, setOpen] = useState(false);
  const [isMobile] = useAtom(isMobileAtom);
  const { navigations } = useHelper();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    setOpen(!mobileOpen);
  }, [mobileOpen]);

  return (
    <Wrapper color={color} mobileOpen={mobileOpen}>
      <header>
        <Link to={'/'} className={styles.cietyLogo}>
          <MobileLogo color={color === 'black' ? CIETY_LG : CIETY_BK} />
        </Link>

        {!isMobile && (
          <ul className={styles.pcMenu}>
            {navigations.map((nav) => (
              <li key={nav.to}>
                <Link
                  to={nav.to}
                  className={classNames({
                    [styles.active]: same(nav.to, location.pathname),
                  })}
                >
                  {nav.label}
                </Link>
              </li>
            ))}
          </ul>
        )}

        <div className={styles.rightSection}>
          <Internationalization
            color={color}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          {isMobile ? (
            <button onClick={handleClick}>
              {mobileOpen ? (
                <Close color={color === 'black' ? '#B2FF00' : '#1C1C24'} />
              ) : (
                <Bars color={color === 'black' ? '#B2FF00' : '#1C1C24'} />
              )}
            </button>
          ) : (
            <a
              href={Links.login}
              className={classNames(
                styles.link,
                color === 'black' ? styles.black : '',
              )}
            >
              Log in
            </a>
          )}
        </div>
      </header>

      <div
        className={classNames(
          styles.modal,
          color === 'black' ? styles.black : '',
          isOpen ? styles.open : styles.close,
        )}
      >
        <ul>
          <li onClick={() => changeLanguage('kr')}>KR</li>
          <li onClick={() => changeLanguage('en')}>EN</li>
        </ul>
      </div>

      {isMobile && mobileOpen && (
        <ul className={styles.mobileMenu}>
          <motion.li
            style={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 }}
          >
            <Link to={'/'}>Home</Link>
          </motion.li>
          <motion.li
            style={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <Link to={'/creator'}>for Creator</Link>
          </motion.li>
          <motion.li
            style={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <Link to={'/web3'}>for Web3</Link>
          </motion.li>
          <motion.li
            style={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <Link to={'/worlds'}>Discover World</Link>
          </motion.li>
          <motion.li
            style={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <Link to={'/faq'}>FAQ</Link>
          </motion.li>
          <motion.li
            style={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className={styles.buttonContainer}
          >
            <motion.button
              className={classNames(
                styles.downloadBtn,
                color === 'black' && styles.white,
              )}
              onClick={() => navigate('/download')}
            >
              Download
            </motion.button>
          </motion.li>
        </ul>
      )}
    </Wrapper>
  );
}

const Internationalization = ({
  color,
  isOpen,
  setIsOpen,
}: {
  color: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <button
        className={styles.internationalization}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        {i18n.language.toUpperCase()}
        <span style={{ transform: isOpen ? '' : 'rotate(180deg)' }}>
          <SmallArrow color={color === 'black' ? CIETY_LG_80 : CIETY_BK} />
        </span>
      </button>
    </>
  );
};
