import {
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';

import { log, onChange, useSequences } from '../../utils/hooks';
import styl from './styles.module.scss';
import { useState } from 'react';
import { responsive } from '../../utils/func';

export default function CleverTurn({ source, containerRef }) {
  const [sequence, loaded] = useSequences(source);
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    target: containerRef,
    offset: ['start start', 'end start'],
  });
  const [currentIndex, setIndex] = useState(0);
  const startX = responsive('0%', '0%', '0%');
  const index = useTransform(
    scrollYProgress,
    [0.07, 0.5],
    [0, sequence.length],
  );
  const translateX = useTransform(
    scrollYProgress,
    [0.07, 0.4],
    [startX, '100%'],
  );

  useMotionValueEvent(index, 'change', (last) => setIndex(Math.round(last)));

  /*
  onChange(translateX, (last) => console.log('translateX : ', last));
  onChange(index, (last) => console.log('translateX : ', Math.round(last)));
  */

  return (
    <motion.div className={styl.clever} style={{ translateX }}>
      <div className={styl.sequence}>
        {loaded ? (
          <motion.img src={sequence[currentIndex]} />
        ) : (
          <img src={source[0]} alt="" />
        )}
      </div>
    </motion.div>
  );
}
